:focus,
a:focus,
button:focus,
input:focus,
*:focus {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
	outline: 0;
}

$primary-rgb: 8, 91, 141;
$secondary-rgb: 22, 172, 75;
:root {
	font-family: $font-family-sans-serif;
	font-size: 16px;
	color: $black;
	--primary-color: #{$primary-rgb};
	--secondary-color: #{$secondary-rgb};
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
input:not([type]),
textarea {
	padding: 0 10px;
}

body {
	font-size: 1rem;
	padding-top: 77px;
	color: $black;
	font-family: $font-family-sans-serif;
	font-family: $font-family-second;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
	font-family: $font-family-second;
	font-weight: 800;
}

.home-h1-tag {
	display: none;
}

a {
	color: $primary;
}

.colorGreen {
	color: #14bb99;
}

.error-msg {
	color: $red;
}

.pad-lft-15 {
	padding-left: 15px;
}

.wrapper {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;

	@media all and (max-width: 767px) {
		background-color: #fff;
	}

	&_1200 {
		max-width: 1200px;
		max-width: 1400px;

		@media all and (min-width: 992px) {
			max-width: 96%;
		}
	}

	&_550 {
		max-width: 550px;
	}

	&_650 {
		max-width: 650px;
	}

	&_1120 {
		max-width: 1120px;
		margin: 0 auto;
	}
}

.full-width {
	width: 100%;
}

.mainClass {
	@media all and (min-width: 768px) {
		min-height: calc(100vh - 230px);
	}
}

.page-404 {
	.banner-homepage .banner {
		margin-bottom: 0;
	}
}

.supplierdetails {
	color: #9e9e9e;
	font-size: 12px;
}

.supplierdetails .address {
	font-size: 11px;
	font-weight: 100;
}

.supplierdetails span {
	display: block;
}

.message-404 {
	position: relative;
	text-align: center;
	margin-top: -40px;
	padding-bottom: 50px;

	.h5 {
		color: $white;
	}

	h1 {
		margin-top: 35px;

		@media all and (max-width: 767px) {
			font-size: 2rem;
		}
	}
}

.link {
	&Text {
		text-decoration: underline;

		&-black {
			color: $text-color;
			font-weight: 400;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
				color: $text-color;
			}
		}

		&-white {
			color: $white;
			font-weight: 400;
			text-decoration: none;

			&:hover {
				text-decoration: underline !important;
			}
		}
	}
}

.no-radius {
	border-radius: 0 !important;
	border: 0;

	&.dropdown-menu {
		padding: 0;
		border: 1px solid #eee !important;
		text-align: left;

		.dropright {
			text-align: left;
		}
	}

	&.btn {
		padding: 0;

		.btn {
			text-align: left;
			background: none;
			color: $black;
			border: 0;
			display: block;
		}
	}

	.btn,
	.dropdown-menu {
		border-radius: 0 !important;
		border: 0;
	}
}

.dropdown-menu {
	min-width: 15rem;
}

.dropdown {
	&-item {
		font-size: 14px;
	}
}

button:focus {
	border: none;
}

img {
	max-width: 100%;
}

.brRadius {
	&-20 {
		border-radius: 20px;
	}
}

.padding {
	&_side {
		&_15 {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}

.bg {
	&Gray {
		background-color: $bgGrey;
	}

	&White {
		background-color: $white;
	}
}

h3 {
	&.catTitle {
		@media all and (min-width: 768px) {
			font-size: 24px;
			color: $text-color;
		}

		a {
			color: $text-color;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

.catTitle {
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
	color: $black2;
	//font-size: 1.75rem;//28px;
	position: relative;

	&.title-withline {
		position: relative;

		&:before {
			content: "";
			width: 100%;
			height: 1px;
			background-color: #f1efef;
			position: absolute;
			top: 34px;
			left: 0;
			z-index: 0;
			display: none;
		}
	}

	.wrapwhite {
		position: relative;
		background-color: #fff;
		padding-left: 0;
		padding-right: 20px;
		z-index: 1;

		@media all and (max-width: 767px) {
			background-color: transparent;
		}
	}

	a {
		color: $black2;

		&:hover {
			text-decoration: none;
		}
	}
}

.btn {
	font-weight: 600;
	transition: all 0.3s linear;

	&-outline {
		border: 1px solid #888;
		// background-color: rgba(var(--secondary-color), 1);
		background-color: $white;
		font-weight: 300;
		font-size: 16px;
		color: #888;
		position: relative;
		z-index: 2;

		&:focus {
			// border: 1px solid rgba(var(--primary-color), 1);
		}

		&:after {
			background: rgba(var(--primary-color), 1);
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
			transform: scale(0.1, 1);
			z-index: -1;
			border-radius: 25px;
			transition: all 0.4s ease;
		}

		&:hover {
			color: $white;

			&:after {
				opacity: 1;
				transform: scale(1, 1);
			}
		}
	}

	&-radius {
		&-20 {
			border-radius: 20px;
		}
	}

	&-orange {
		color: $white;
		background-image: linear-gradient(
			-45deg,
			$orange,
			lighten($orange, 35%)
		);
		border: none;

		&:hover {
			color: $white;
			background-image: linear-gradient(
				-45deg,
				lighten($orange, 35%),
				$orange
			);
		}
	}
}

//swiper
.prev,
.next {
	position: absolute;
	left: 0;
	//top: 50%;
	top: 120px;
	z-index: 1;
	background-color: #fff;
	width: 25px;
	height: 25px;
	border-radius: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	border: 1px solid #ccc;
	box-shadow: 0 4px 4px #dedada;

	span {
		display: inline-flex;
		font-size: 14px;
		position: relative;
		justify-content: center;
		align-items: center;
	}

	&:focus {
		border: 1px solid #ccc;
		box-shadow: 0 4px 4px #dedada;
	}
}

.next {
	right: 0;
	left: auto;

	span {
		left: 1px;
	}
}

.swiper-container {
	max-width: calc(100% - 50px);
}

@media all and (max-width: 767px) {
	.suppliers-single-page {
		.category_wrapper-list {
			margin-top: 0;
			padding-top: 10px;
			padding-bottom: 5px;
		}
	}

	.suppliers-contents.col-sm-3 {
		margin-bottom: 20px;
	}

	body.background_fixed {
		position: fixed;
	}
}

@media all and (max-width: 414px) {
	.swiper-container {
		max-width: 100%;
		// max-width: 320px;
	}
}

.por {
	position: relative;
}

.content-with-icon {
	display: flex;
	// opacity: 0.8;
}

.wrapper-three-col-footer {
	border-top: 1px solid #f1efef;
	border-bottom: 1px solid #f1efef;
	padding: 20px 40px 10px;
	display: none;

	.col-md-4 {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	p {
		margin-bottom: 0;
	}
}

.three-col {
	&-footer {
		&-icon {
			flex: 1;
			max-width: 80px;

			.icon {
				font-size: 25px;
				padding-right: 10px;
				color: rgba(var(--secondary-color), 1);
				color: #696e79;

				&-user-circle {
					font-size: 36px;
					position: relative;
					top: -10px;
				}
			}
		}

		&-content {
			flex: 1;
			margin-bottom: 10px;

			h6 {
				font-size: 12px;
				font-weight: 500;
				margin-bottom: 2px;
			}

			p {
				font-size: 14px;
				color: #696b79;
				font-weight: 300;
				line-height: 1.3;
			}
		}
	}
}

.justify-center {
	justify-content: center;
}

.width260 {
	max-width: 260px;
}

//pagination
/*pagination*/
.pagination_wrapper {
	padding-top: 20px;
	padding-bottom: 10px;
	display: flex;
	justify-content: space-between;
}

.pagination_wrapper ul,
.pagination_wrapper ul li {
	list-style: none;
	display: inline-block;
	margin-bottom: 0;
	vertical-align: top;
}

.page_item + .page_item {
	// padding-left: 5px;
	// padding-right: 5px;
}

.page_link {
	width: 36px;
	height: 30px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #707070;

	&.page_prev,
	&.page_next {
		width: 30px;
		border: 1px solid #d6d1d1;

		svg {
			path {
				stroke: #707070;
			}
		}

		span {
			border-left: none;
		}
	}
}

.page_link:hover {
	text-decoration: none;
	color: $black;
	background-color: transparent;
}

.active .page_link {
	color: $black;
}

.disabled .page_link {
	background-color: #ccc;
	color: #999;
	font-weight: 600;
}

.prevClass {
	.page_link {
		// padding-right: 7px;
	}

	+ .page_item {
		.page_link {
			span {
				border-left: none;
			}
		}
	}
}

.nextClass .page_link {
	// padding-left: 7px;
}

.active .page_link:hover {
	//background-color: #E6E6E6;
	color: #999;
}

.page_link > span {
	font-size: 20px;
	font-weight: 300;
	display: flex;
	align-items: center;
	justify-content: center;
}

.page_item {
	span {
		width: 35px;
		border-left: 1px solid #eee;
	}

	&.active {
		span {
			width: 32px;
			height: 30px;
			border-radius: 4px;
			color: $black;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
		}
	}
}

.pagination-items {
	font-style: italic;
	font-size: 17px;
	display: inline-flex;
	align-items: center;
}
//end

//Category Page
.btn-add-cart {
	bottom: 0;
	width: auto;
	opacity: 0;
	font-size: 12px;
	transition: all 0.2s linear;
	backface-visibility: hidden;
	transition-delay: 0.1s;

	@media all and (min-width: 1025px) {
		position: absolute;
		transform: translateY(2rem);
	}
}

.suppliers-single-page.category_wrapper {
	padding-top: 0;
}

.product {
	&-name {
		font-weight: 600;
		color: $black;
		display: block;
		line-height: 1.15;
		margin-bottom: 5px;
	}

	&-distance {
		background-color: transparent;
		border: 1px solid rgba(0, 0, 0, 0.3);
		color: rgba(0, 0, 0, 0.3);
		display: inline-block;
		padding: 2px 10px 2px 5px;
		border-radius: 20px;
		font-size: 11px;

		@media all and (max-width: 1025px) {
			border: 1px solid rgba(var(--primary-color), 1);
			color: rgba(var(--primary-color), 1);
		}

		.icon {
			padding-right: 3px;
			// color: rgba(var(--primary-color), 1);
		}

		+ .start-ratings {
			margin-left: 8px;
		}
	}
}

.icon {
	&-fav {
		cursor: pointer;
	}

	&-heart {
		&-black {
			color: rgba(var(--primary-color), 1);
			cursor: pointer;
			display: flex;
			justify-content: flex-end;
			position: relative;
			// text-shadow: 0 0 2px #333;
			top: 2px;
		}

		&-red {
			color: rgba(var(--primary-color), 1);
			text-shadow: none !important;

			&.icon-heart:before {
				content: "\e830";
			}
		}
	}
}

.miniTitle {
	font-weight: 600;
	font-size: 15px;
	color: $text-color-3;
	text-transform: uppercase;

	&-div {
		margin-bottom: 20px;
		border-bottom: 1px solid #eaeaec;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media all and (min-width: 768px) {
			height: 53px;
		}
	}
}

.section_filter {
	margin-bottom: 10px;

	&-search {
		overflow: hidden;
		position: relative;
		color: $text-color-2;
		// opacity: 0.5;
		// margin-bottom: 10px;
		display: flex;

		button.search-btn {
			position: absolute;
			right: 0;
			top: 0;
			width: 15px;
			height: 100%;
			padding-left: 10px;
			background: #f5f5f6;
			border: 1px solid #f5f5f6;
			border-left: none;
			border-radius: 0 4px 4px 0;

			.icon_search {
				position: relative;
				right: 5px;
			}
		}

		.search_input {
			padding-left: 10px;
			height: 39px;
			background: #f5f5f6;
			border: 1px solid #f5f5f6;
			border-radius: 4px 0 0 4px;

			&::placeholder {
				text-transform: none;
				// font-family: 'Poppins', sans-serif;
				// font-style: italic;
				font-weight: 400;
				font-size: 12px;
			}
		}
	}

	&-bycatname {
		padding-top: 10px;

		.btnWrapper {
			margin-top: 15px;
			justify-content: flex-start;

			.btn {
				padding-left: 20px;
				padding-right: 20px;
				font-size: 12px;
				// font-family: "Poppins", sans-serif ;
				opacity: 0.7;
			}
		}
	}

	input.search_input {
		&:focus {
			border-color: #eaeaec;
			background-color: $white;

			+ button.search-btn {
				border: 1px solid #eaeaec;
				border-left: 0;
				background-color: $white;
			}
		}
	}
}

.filter-bycatname {
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	font-weight: 300;
	padding-bottom: 2px;

	[type="checkbox"] + label {
		padding-left: 26px;
		cursor: pointer;

		&:before {
			// box-shadow: inset 0 0 0 1px rgba(0,0,0,0.5);
			width: 16px;
			height: 16px;
		}
	}
}

.breadcrumb {
	&-section {
		padding-bottom: 20px;
		padding-top: 20px;
	}
	&-div {
		padding-bottom: 2px;
	}

	&-item {
		font-family: $font-family-second;
		position: relative;
		text-transform: capitalize;
		font-weight: 400;
		color: $text-color-2;
		display: inline-block;

		a {
			color: $black;
			opacity: 0.7;
			font-weight: 400;

			&:hover {
				color: $black;
				text-decoration: none;
				opacity: 1;
			}
		}
	}
}

.section_sort {
	display: flex;
	justify-content: flex-end;

	&-div {
		font-size: 14px;
		color: #282c3f;
		cursor: pointer;
		position: relative;
		box-sizing: border-box;
		border-radius: 2px;
		background-color: #fff;
		border: 1px solid #d4d5d9;
		font-size: 14px;
		width: 255px;

		@media all and (max-width: 767px) {
			width: 100%;
		}

		label {
			white-space: nowrap;
			padding-right: 10px;
			margin-bottom: 0;
		}

		select {
			border: none;
			border-radius: 0;
			height: 40px;
			min-width: 170px;
			padding: 5px 13px;
			background-position: right 8px center;
			-webkit-appearance: none;
			-moz-appearance: none;
		}

		select:not([multiple]) {
			background-image: url(../images/sort.png);
			display: inline-block;
			background-size: 15px 8px;
		}
	}
}

@media all and (min-width: 768px) {
	.section_sort.supplier_sort {
		position: absolute;
		right: 350px;
	}
}

//About Page
.aboutPage {
}

.bgColor_sec {
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: rgba(var(--primary-color), 1);
}

.title {
	&-small-banner {
		color: $graywhite;
		font-size: 1.5625rem;

		@media all and (max-width: 767px) {
			font-size: 1.3rem;
		}
	}
}

.sell {
	&Step {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		&:last-child {
			padding-bottom: 20px;
		}
	}

	&Title {
		padding-top: 10px;
		padding-bottom: 6px;
		position: relative;

		&:before,
		&:after {
			content: "....";
			letter-spacing: 2px;
			font-size: 23px;
			display: flex;
			flex-direction: column;
			color: $red2;
			transform: rotate(90deg);
			position: relative;
			left: 6px;
		}
	}

	&Text {
		color: $text-color-2;
		font-size: 1.25rem;
		padding: 5px 0;
	}

	&Num {
		width: 30px;
		height: 28px;
		background-color: $red2;
		color: #fff;
		border-radius: 3px;
		position: relative;
		margin: 0 auto;

		&:after {
			content: "";
			top: 100%;
			left: 50%;
			border: solid transparent;
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(136, 183, 213, 0);
			border-top-color: $red2;
			border-width: 6px;
			margin-left: -6px;
		}
	}

	&Content {
		max-width: 630px;
		font-size: 1.0625rem;
		font-weight: 300;
		line-height: 1.64705882353;
	}

	&Image {
		margin-bottom: 8px;
	}

	&-btn {
		font-size: 1.5625rem;
		font-weight: 400;
	}
}

//termsPage
.termsPage,
.policyPage,
.howtosellPage,
.aboutPage {
	.innerContent {
		p,
		ul,
		ol {
			font-size: 0.9375rem;
			font-weight: 300;
			color: $black;
			font-family: $font-family-sans-serif;
		}

		ul {
			padding-left: 0;

			li {
				position: relative;
				padding-left: 14px;

				&:before {
					content: "";
					background-color: $black;
					width: 6px;
					height: 6px;
					border-radius: 50%;
					position: absolute;
					left: 0;
					top: 7px;
				}
			}
		}

		h3 {
			font-size: 1rem;
			font-weight: 800;
		}
	}

	.wrapper_1200 {
		padding-right: 25%;

		@media all and (max-width: 1200px) {
			padding-right: 15%;
		}

		@media all and (max-width: 575px) {
			padding-right: 40px;
		}
	}
}

.innerContent {
	padding-top: 45px;
	padding-bottom: 40px;
	font-size: 1.0625rem;
	line-height: 1.70588235294;

	&-title {
		font-size: 1.625rem;
		margin-bottom: 25px;
	}

	.link {
		&Text {
			color: $text-color-2;

			&:hover {
				text-decoration: none;
			}
		}
	}

	h3 {
		font-size: 1rem;
	}
}

.wrapperBoxshadow {
	box-shadow: 0 3px 21px rgba(0, 0, 0, 0.3);
	padding: 20px;
	border-radius: 10px;
}

//How to sell page
.howtosellPage {
	.btnWrapper {
		margin-bottom: 30px;
	}

	.innerContent {
		padding-top: 0;
		text-align: center;
		margin: 0 auto;
		max-width: 500px;

		h3 ~ h3 {
			margin-top: 30px;
		}

		@media all and (max-width: 767px) {
			.wrapper {
				padding-top: 20px;
			}

			.socialShareWrap {
				background-color: #fff;
				padding: 10px;
				margin-top: 10px;
				max-width: 100%;
			}
		}
	}

	.wrapper_1200 {
		padding-right: 15px;

		@media all and (max-width: 1200px) {
			padding-right: 15px;
		}

		@media all and (max-width: 575px) {
			padding-right: 15px;
		}
	}
}

//Login Design
.login {
	.btnWrapper {
		justify-content: space-between;

		.btn {
			font-size: 1rem;
			font-weight: 600;
		}
	}
}

.popup-content {
	width: 100% !important;
	max-width: 560px;
	border-radius: 10px;
	padding: 0 !important;
	box-shadow: 1px 6px 25px rgba(0, 0, 0, 0.32);
	max-height: calc(100vh - 30px);
	scroll-behavior: smooth;

	.modalHeader {
		padding: 10px 12px 0 20px;
		border-bottom: 1px solid #ddd;
		font-size: 18px;
		font-weight: 400;
		color: $black;

		p {
			margin-bottom: 10px;
		}
	}

	.modalBody {
		padding: 10px 20px;
		//text-align: center;
		font-weight: 400;
		color: $text-color-2;
		// max-height: calc( 100vh - 48px - 30px );
		// overflow: auto;
		// overflow-x: hidden;

		p {
			font-size: 0.875rem;
		}

		.text-medium {
			padding-top: 10px !important;
			padding-bottom: 10px !important;
			display: block;
			text-align: center;
		}

		.form-group {
			text-align: left;
		}

		.form-label {
			margin-bottom: 0.2rem;
			font-size: 14px;
		}

		.btnWrapper {
			margin-bottom: 15px;

			.btn {
				font-weight: 400;
			}
		}

		.text-condition {
			.form-label {
				font-size: 11px;
			}
		}
	}
}

.customCheckbox {
	.labelCheckbox {
		padding-left: 28px;
		position: relative;
	}

	input[type="checkbox"] {
		position: absolute;
		left: 3px;
		top: 5px;

		&:checked {
			+ label {
				&:after {
					content: "";
					position: absolute;
					left: 4px;
					top: 11px;
					background: white;
					width: 2px;
					height: 2px;
					box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
						4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
					transform: rotate(45deg);
				}
			}
		}
	}

	&.text-condition {
		input[type="checkbox"] {
			top: 2px;
		}
	}
}

.custom-control {
	padding-left: 0;

	&-label {
		&:after {
			left: 0;
		}
	}

	&-input {
		&:checked {
			~ {
				.custom-control-label {
					&:before {
						border-color: rgba($black, 0.5);
						background-color: rgba($black, 0.5);
						box-shadow: 0 0 0 0.2rem rgba(155, 156, 156, 0.25);
					}
				}
			}

			+ label {
				&:before {
					box-shadow: none;
				}
			}
		}
	}
}

.differentshipping {
	.customCheckbox {
		padding-top: 20px;
	}
}

.btn {
	&-signFb {
		height: 35px;
		background-color: $blue-fb;
		border-radius: 5px;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 0.9375rem;
		line-height: 1.26666666667;

		&:hover {
			color: $white;
			text-decoration: none;
		}

		.icon-facebook-f {
			font-size: 1.1rem;
		}
	}
}

.text-medium {
	font-size: 1rem;
	font-weight: 600;
	padding: 0 10px !important;
}

.inputBox {
	border: 1px solid #cac5c5 !important;
	border-radius: 5px !important;
}

.forgotpwd {
	font-size: 15px;
	font-weight: 300;
	color: $black;
	padding-top: 4px;
	padding-left: 0;
	padding-right: 0;

	&-div {
		float: right;
		padding-bottom: 30px;
	}

	&:hover {
		text-decoration: underline;
	}
}

.mrg-left-15 {
	margin-left: 15px;
}

.mrg-bot-10 {
	margin-bottom: 10px !important;
}

.fontSize11 {
	font-size: 11px;
}

button {
	&.close {
		width: 12px;
		color: #333;
		font-size: 20px;
		line-height: 1;
		font-family: arial;

		&:hover {
			color: #333;
			background-color: transparent;
		}
	}
}

//forgotPwd
.validatecode,
.forgotPwd,
.signUp {
	.btnWrapper {
		justify-content: flex-start;

		.btn + .btn {
			margin-left: 10px;
		}
	}
}

//signup
.signUp {
	.mapicon {
		top: 27px;

		button.button {
			height: 32px;
		}
	}
}

.currency {
	display: none;
}
//cart Page
.cart {
	&Page {
		padding-top: 40px;
		padding-bottom: 40px;

		.cartAmnt {
			display: flex;
			justify-content: flex-end;
			text-align: right;
			// align-items: center;
			padding-left: 10px !important;
			font-weight: 600;
			font-size: 17px;
			color: rgba(var(--primary-color), 1);
		}

		.row {
			.col-12 {
				max-height: 825px;
			}
		}

		.userlistWrapper {
			overflow-y: auto;
			max-height: 732px;

			&.opacity {
				opacity: 0.7;
			}
		}
	}

	&-header {
		margin-bottom: 10px;

		.cartItems,
		.cartPrice,
		.cartAmnt,
		.cartQty {
			font-size: 15px;
			color: #010101;
			font-weight: 400;
		}

		.cartQty {
			padding-left: 10px;
		}
	}

	&-items {
		display: flex;
		justify-content: space-between;
		background-color: $white;
		padding: 5px 20px;
		border-radius: 4px;

		&:last-child {
			.col1,
			.col2,
			.col3,
			.col4 {
				border-bottom: none;
			}
		}

		.productdetails {
			flex: 2;
			padding-left: 20px;

			h3 {
				font-size: 16px;
				font-weight: 600;
			}

			p {
				font-size: 12px;
				line-height: 18px;
				font-weight: 300;
			}
		}

		.cartPrice,
		.cartQty {
			flex: 1;

			.pointer-event {
				pointer-events: none;
			}
		}

		.cartAmnt {
			flex: 0.8;
		}
	}

	&Items {
		/*flex: 2.8;*/
		flex: 2;
		display: flex;
	}

	&product-image {
		flex: 1;
		justify-content: center;
		align-items: center;

		.imgWrapper {
			border: 1px solid #eee;
			border-radius: 4px;
			padding: 10px;
		}
	}

	&-shiptext {
		font-size: 13px;

		.lightText {
			font-weight: 300;
		}
	}

	&Price {
		display: flex;
		justify-content: flex-end;
		text-align: right;
		align-items: flex-start;
	}

	&Qty {
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;

		.inputBox {
			height: 30px;
			padding: 0 10px;
			width: 62px;
			text-align: center;
		}
	}

	&-delete {
		position: absolute;
		top: 10px;
		left: 10px;
		font-size: 18px;
		opacity: 0.5;
		cursor: pointer;
	}

	&-order {
		// background-color: $white;
		// border-radius: 4px;
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&-placeorder {
		// flex: 1 42px;

		&-btn {
			background-color: rgba(var(--primary-color), 1);
			color: #fff;
			border-radius: 4px;
			margin-bottom: 10px;
			text-align: center;
			padding: 6px;
			font-size: 17px;

			.placeorder-text {
				color: $white;
				text-decoration: none;
				display: block;
				font-size: 17px;
			}

			&:hover {
				background-color: rgba(var(--secondary-color), 1);
			}
		}
	}

	&-shipping {
		flex: 1 100%;
		padding: 0.75rem 1.25rem;
		background-color: $white;

		+ .cart-placeorder {
			.order-payment {
				background-color: $white;
			}

			.cart-placeorder-btn {
				border-radius: 4px;
				margin-top: 10px;
				margin-bottom: 0;
			}
		}
	}

	&Update {
		justify-content: flex-end;
		margin-top: 0;
		background-color: $white;
		padding: 20px;

		.btn {
			font-weight: 400;

			&-success {
				background-color: $green1;
				border: none;

				&:hover {
					background-color: darken($green1, 5%);
				}
			}
		}
	}
}

.price {
	font-size: 17px;
	font-weight: 600;
	color: $red2;
	// font-family: 'Poppins', sans-serif;
}

.smallText {
	display: block;
	font-size: 10px;
	font-weight: 300;
	font-family: $font-family-heebo;

	@media all and (max-width: 767px) {
		margin-top: 0;
		font-size: 14px;
		color: rgba(var(--secondary-color), 1);
		font-weight: 500;
		margin-bottom: 4px;
	}
}

.userList {
	position: relative;

	.cartItems,
	.cartPrice,
	.cartQty,
	.cartAmnt {
		border-bottom: 1px solid #eee;
		padding: 20px 0;
	}
}

.font-16reg {
	font-size: 16px;
	color: $black2;
	padding-bottom: 5px;
}

.shipping {
	&_location,
	&_address {
		display: flex;
	}

	&-address {
		.icon-maps-and-flags {
			color: #1f346b;
			padding-right: 10px;
		}
	}

	&_text {
		font-size: 13px;
		font-weight: 300;
		padding-left: 0.625rem;
		padding-top: 3px;
	}

	&_bill,
	&_tell,
	&_mail,
	&_location {
		display: flex;
		justify-content: space-between;

		.icon {
			width: 15px;

			&:before {
				margin: 0;
			}
		}

		.icon-mailing {
			font-size: 10px;
			padding-top: 5px;
		}
	}

	&-title {
		padding-bottom: 5px;
		display: inline-block;
	}

	&price {
		font-size: 0.875rem; //14px
		display: block;
		color: #565555;
		font-weight: 600;

		&_text {
			font-weight: 300;
		}
	}
}

.quantity {
	margin-left: 10px;
	display: flex;
	align-items: center;

	.button-minus {
		padding-left: 0;
		padding-right: 5px;
	}

	.button-plus {
		padding-left: 5px;
		padding-right: 0;
		margin-right: 0;
	}
}

.order {
	&-summary {
		padding-top: 20px;
		padding-bottom: 20px;

		&-div {
			display: flex;
			justify-content: space-between;
			font-size: 14px;
			font-weight: 300;
			padding: 3px 0;
		}
	}

	&-total {
		border-top: 1px solid #ccc;
		padding-top: 5px;
		margin-top: 5px;
		font-weight: 600;
		font-size: 17px;
	}

	&-payment {
		text-align: center;
		padding: 20px;

		&-cash {
			border-bottom: 1px solid #ccc;
			padding-bottom: 10px;
			margin-bottom: 40px;

			.imageWrapper {
				margin-bottom: 10px;
			}
		}
	}
}

//than you page
.whiteBox {
	background-color: $white;
	border-radius: 5px;
}

.thankyouPage {
	padding-top: 50px;
	padding-bottom: 50px;

	.whiteBox {
		margin-bottom: 20px;
	}

	.cart {
		&-header {
			border-bottom: 1px solid #f1efef;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;

			.col {
				font-size: 1.125rem; //18px
				padding: 20px 0 13px;
			}
		}

		&-items {
			.col {
				&1 {
					flex: 1.7;
				}

				&2 {
					flex: 1;
					padding: 20px 0;
				}

				&3 {
					flex: 0.7;
					text-align: left;
					justify-content: flex-start;
				}

				&4 {
					flex: 0.3;
					justify-content: flex-start;
				}
			}

			.cartItems {
				padding-right: 50px;
			}

			.cartproduct-image {
				max-width: 100px;
			}
		}
	}

	.linkText-black {
		text-decoration: none;
		border-radius: 20px;
		padding: 7px 20px;
		color: #888;
		position: relative;
		z-index: 2;
		display: inline-flex;
		border: 1px solid #888;

		&:after {
			background: rgba(var(--primary-color), 1);
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
			transform: scaleX(0.1);
			z-index: -1;
			border-radius: 25px;
			transition: all 0.4s ease;
		}

		&:hover {
			border-color: rgba(var(--primary-color), 1);
			text-decoration: none;
			color: #fff;

			&:after {
				opacity: 1;
				transform: scale(1);
			}
		}
	}
}

.thank {
	&-msg {
		&-bigtext {
			font-size: 25px;
			color: rgba(var(--primary-color), 1);
			padding-bottom: 10px;

			display: inline-block;
		}

		&.whiteBox {
			display: flex;
			justify-content: space-between;
			text-align: center;
			padding: 30px;
			margin-bottom: 0;
		}
	}
}

.checkout {
	&Summary {
		&.whiteBox {
			margin-top: -18px;
			padding: 20px 40px;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		&Top {
			display: flex;
			justify-content: space-between;
		}

		&Bottom {
			display: flex;
			justify-content: space-between;
			padding-top: 40px;
			padding-bottom: 30px;
		}
	}

	&Email {
		border: 2px solid #ddd;
		border-radius: 5px;
		padding: 15px 20px;
		font-weight: 300;
		margin-top: 10px;

		span.icon-mailing {
			color: $blue1;
			width: 38px;
			display: inline-block;
		}
	}

	&Status {
		color: $text-color-deam;
	}
}

.order_summary {
	background-color: #eee;
	padding: 20px;
	width: 40%;
	display: flex;
	justify-content: space-between;
}

.btn-continue {
	width: 32%;

	.btn-danger {
		padding: 18px;
		font-size: 20px;
		font-weight: 400;
	}
}

.emptyData {
	border-radius: 5px;
	padding: 20px;
	padding-top: 0;
	background-color: $white;
	text-align: center;
	color: $text-color-2;
}

//Sticky footer
html,
body,
#root,
.mainWrapper {
	display: flex;
	min-height: calc(100vh - 135px);
	flex-direction: column;
}

body {
	overflow-y: scroll;
}

.views {
	flex: 1;
	display: flex;

	> div {
		flex: 1;
		overflow: hidden;
	}
}

//Single Page
.category_wrapper {
	&-single {
		.breadcrumb-div {
			padding-top: 15px;
			padding-bottom: 15px;
			align-items: center;
			display: flex;

			@media all and (max-width: 767px) {
				display: inline-block;
			}
		}
	}
}

.productImage {
	border: 1px solid $graywhite;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 50px 20px;
	margin-bottom: 20px;

	&Thumb {
		border: 1px solid $graywhite;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px;
	}
}

.single {
	&titlesection {
		border-bottom: 1px solid $grey2;
		margin-bottom: 15px;
		padding-bottom: 15px;
		margin-top: 20px;

		h2 {
			font-size: 24px;
			// font-weight: 600;
		}
	}

	&Rating {
		font-weight: 300;
		font-size: 14px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&-wrapper {
			display: flex;
			align-items: center;
		}

		.star-ratings {
			margin-top: -4px;
			margin-right: 10px;
		}

		.icon-fav {
			font-size: 24px;
			color: #757575;

			&:before {
				margin-right: 0;
			}
		}

		.proDistance {
			font-weight: 400;
			// margin-left: 10px;
			padding: 5px 6px;
			border: 1px solid rgba(0, 0, 0, 0.3);
			border-radius: 30px;
			background: none;
			color: rgba(0, 0, 0, 0.3);

			.icon {
				// color: rgba(var(--primary-color), 1);

				&:before {
					margin: 0;
				}
			}
		}
	}

	&Brands {
		font-size: 14px;
		margin-top: 5px;

		span {
			color: #9e9e9e;
			margin-right: 4px;
		}
	}

	&price {
		padding: 10px 0;

		@media all and (max-width: 767px) {
			padding: 0;
		}
	}

	&btnSection {
		display: flex;
		align-items: center;

		.inputBox {
			width: 70px;
			margin-left: 5px;
			margin-right: 5px;
			padding: 0 5px;
			text-align: center;
		}

		.btn {
			&-outline-danger {
				width: 130px;
				margin-right: 15px;
			}

			&-danger {
				width: 150px;
				margin-right: 10px;
			}
		}

		.icon-heart {
			font-size: 23px;
		}

		&Left {
			display: flex;
			align-items: center;
		}
	}
}

input.button-minus,
input.button-plus {
	border: none;
	padding: 0;
	font-size: 30px;
	line-height: 1;
	// margin-top: -6px;
	padding-left: 12px;
	background-color: transparent;
}

input.button-plus {
	font-size: 20px;
	margin-left: 0;
	margin-right: 15px;
	margin-top: 0;
	padding-left: 0;
}

.detailsSec {
	font-size: 14px;
	padding-bottom: 20px;

	strong {
		font-weight: 400;
		color: #9e9e9e;
	}

	.instock {
		padding: 2px 8px;
		background: rgba(var(--secondary-color), 1);
		color: $white;
	}

	.singleprice {
		margin-top: 10px;
	}

	.regularprice {
		font-size: 20px;
		line-height: 1;
	}

	.saleprice {
		font-size: 30px;
		line-height: 1.15;
		font-family: $font-family-second;
		font-weight: 400;
	}
}

.soldby {
	&-phn {
		.icon-call-answer {
			position: relative;
			// top: -2px;
		}
	}

	&-seller {
		font-size: 17px;
		line-height: 1.15;
		margin-bottom: 8px;
	}

	.soldby_icon-link {
		.icon-mailing {
			color: #4c4b4b;
		}
	}
}

.verified {
	font-weight: 600;

	&-logo {
		max-width: 30px;
	}
}

.delivery {
	&options {
		margin-bottom: 20px;

		.smallText {
			font-size: 13px;
			line-height: 1.15;
		}

		.soldby {
			height: 100%;
			padding: 13px 16px 10px;

			&-text {
				background-color: #e8e6e6;
				padding: 10px 20px;
				text-align: center;
				position: absolute;
				top: 0;
				left: 15px;
				right: 15px;
			}

			&-title {
				color: #757575;
				font-size: 14px;
				margin-bottom: 4px;
			}

			&-logo {
				margin-bottom: 0;
				margin-top: 5px;
				max-width: 120px;
				margin-left: 0;
				margin-right: auto;
			}

			&-name {
				font-size: 18px;
				font-weight: 700;
				padding-bottom: 0;
				margin-bottom: 5px;
				margin-top: 5px;
			}

			&-contact {
				border-top: 1px solid rgba($black, 0.2);
				margin-top: 8px;
				padding-top: 12px;
			}

			.proDistance {
				font-size: 12px;
				height: auto;
				font-weight: 400;
				margin-top: 6px;
				padding: 4px 6px 4px 6px;
				border: 1px solid rgba(0, 0, 0, 0.3);
				border-radius: 30px;
				background: none;
				color: rgba(0, 0, 0, 0.3);

				@media all and (max-width: 767px) {
					color: rgba(var(--primary-color), 1);
					border-color: rgba(var(--primary-color), 1);
				}

				.icon {
					// color: rgba(var(--primary-color), 1);

					&:before {
						margin: 0;
					}
				}
			}

			&-distance {
				display: flex;
				justify-content: space-between;
				padding-bottom: 5px;
			}

			&-address {
				font-size: 13px;
				font-weight: 400;
				padding-bottom: 5px;
				margin-top: 5px;

				.core-address {
					margin-right: 8px;
					display: block;
				}

				a {
					display: inline-block;
				}

				.icon-map-marker-alt:before {
					margin-left: 0;
				}
			}

			&-contactname {
				font-size: 15px;
				font-weight: 600;
				color: lighten($black, 10%);
				padding-bottom: 5px;
			}

			&-phn {
				font-size: 14px;
				font-weight: 400;
				// color: lighten($black, 20%);
				padding-bottom: 5px;
			}

			&-bot {
				display: flex;
				justify-content: space-between;
				margin-top: 10px;
				padding-top: 10px;
				border-top: 1px solid lighten($black, 80%);
			}

			.verified {
				font-size: 13px;
				margin-bottom: 10px;
				margin-top: 10px;

				img {
					padding-right: 5px;
				}
			}

			&-time {
			}
		}
	}

	&-list {
		&-div {
			// padding-bottom: 10px;
			font-size: 14px;
		}

		&1,
		&2 {
			display: flex;
			// margin-bottom: 10px;

			.icon {
				width: 26px;
				font-size: 14px;
				margin-right: 5px;
			}
		}
	}
}

.attachment {
	&-image {
		img {
			max-width: 60px;
			cursor: zoom-in;
		}
	}

	img {
		margin-top: 5px;

		+ img {
			margin-top: 10px;
		}
	}
}

.warranty {
	&-div {
		font-size: 14px;
	}

	&-text {
		padding-top: 3px;
		font-weight: 600;
	}

	&-list {
		.smallText {
			line-height: 1.25;
			font-weight: 400;
		}

		&1,
		&2,
		&3 {
			display: flex;
			/*align-items: center;*/
			margin-bottom: 5px;
		}

		.icon-tick {
			// color: $green;
			font-size: 16px;
			width: 20px;
			margin-right: 5px;

			&:before {
				content: "\F328";
			}
		}
	}
}

.title-sub {
	border-bottom: 1px solid #ddd;
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.description {
	&-wrapper {
	}

	&Specification {
		position: relative;
		background-color: $white;
		padding: 30px 20px;
		margin-top: 20px;
		margin-bottom: 20px;

		@media all and (max-width: 767px) {
			padding: 0;
			margin-top: 0;
		}

		h3 {
			font-size: 18px;
			font-weight: 600;
			// color: #212121;
			color: #757575;

			@media all and (max-width: 767px) {
				font-size: 12px;
				color: #535766;
				letter-spacing: 1.5px;
				font-family: Heebo, sans-serif;
				font-weight: 600;
				text-transform: uppercase;
				padding-bottom: 10px;
			}
		}

		.description,
		.specification {
			margin-bottom: 20px;
			padding-bottom: 20px;
			border-bottom: 1px solid lighten($black, 90%);
			font-family: Arial, sans-serif;
			font-size: 14px;
			line-height: 1.3;
		}

		.specification {
			padding-bottom: 0;
		}

		+ .descriptionSpecification {
			margin-top: 0;
		}
	}
}

.specification {
	&-type {
		font-family: $font-family-sans-serif;
		font-weight: 600;
		color: #757575;
		font-size: 14px;
	}

	.col-md-3 {
		padding-bottom: 20px;
	}
}

.hidden {
	display: none !important;
}

.loaderspinner {
	margin-right: 3px;
}

// rating detail
ul,
li {
	list-style: none;
}

.mod-rating {
	.left {
		display: flex;

		.summary {
			padding-right: 20px;
		}
	}

	.detail {
		li {
			display: flex;
		}
	}
}

.percent {
	padding-left: 15px;
	font-size: 13px;
	position: relative;
	top: 4px;
}

.average {
	img {
		width: 30px;
		height: 30px;
	}
}

.count {
	font-size: 14px;
	padding-top: 5px;
}

.pdp-review-progress {
	display: inline-block;
	position: relative;
	width: 138px;
	height: 12px;
}

.pdp-review-progress .bar {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.pdp-review-progress .bg {
	background: #eff0f5;
}

.pdp-review-progress .fg {
	background: #faca51;
}

span.progress-wrap {
	margin-left: 10px;
	margin-top: 2px;
}

.progress-title {
	img {
		width: 15px;
		height: 15px;
	}
}

span.score-average {
	font-size: 40px;
}

span.score-max {
	font-size: 20px;
	opacity: 0.6;
}

//
.pdp-mod-filterSort {
	height: 44px;
	border-top: 1px solid #eff0f5;
	border-bottom: 1px solid #eff0f5;

	.title {
		display: inline-block;
		height: 44px;
		line-height: 44px;
		font-size: 14px;
		color: #212121;
	}

	.oper {
		float: right;
		display: inline-block;
		padding: 0 12px;
		height: 44px;
		line-height: 44px;
		border-left: 1px solid #eff0f5;
		font-size: 13px;
		color: #757575;
		cursor: pointer;
	}
}

.mod-reviews {
	display: flex;

	.item {
		padding: 10px 1px;
		color: #999;
		font-size: 12px;
		border-bottom: 1px solid #eff0f5;
		float: left;
		width: 100%;
	}

	.middle {
		margin: 5px 0 15px;
		float: left;
		width: 100%;

		.icon-tick {
			margin-left: 10px;
		}
	}

	.content {
		color: #212121;
		font-size: 14px;
		white-space: pre-wrap;
		word-break: break-word;
	}

	.skuInfo {
		font-size: 12px;
		margin-top: 8px;
	}

	.bottom {
		margin-top: 8px;

		.like-icon {
			color: #bababa;
			margin-right: 7px;
		}
	}

	.top {
		float: left;
		width: 100%;

		.left {
			float: left;
			cursor: pointer;
		}

		.right {
			float: right;
		}
	}

	.container-star {
		img {
			width: 20px;
			height: 20px;
		}
	}
}

//related products
.relatedProduct {
	.categoryList {
		.link {
			.btn.btn-add-cart {
				max-width: 100px;
			}

			&:hover {
				text-decoration: none;

				.btn.btn-add-cart {
					opacity: 1;
					max-width: 100px;

					@media all and (min-width: 768px) {
						transform: translateY(-15px);
					}
				}
			}
		}

		.imgWrapper {
			margin-bottom: 30px;
			height: 200px;
		}
	}
}

.no-touch-device {
	.footer {
		&_phone-div a,
		&_email a {
			pointer-events: none;
		}
	}

	a.click-to-call {
		pointer-events: none;
		color: inherit;
	}
}

.home-product_wrapper.bgWhite {
	@media all and (min-width: 768px) {
		padding-top: 20px;
	}
}

.nav_categories .dropdown-menu .remove-arrow {
	& .dropdown-menu {
		background-color: transparent;
	}
}

.nav_categories .dropdown-categories + .dropdown-menu {
	.full-width {
		width: 99%;
		border-bottom: 1px solid #eee;
	}
}

.nav_categories .dropdown-menu .remove-arrow.dropright:after {
	display: none;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
	position: static;
}

.sub-category {
	a.dropdown-item {
		border: 1px solid #eee;
		border-bottom: none;
	}

	&:last-child {
		a.dropdown-item {
			border-bottom: 1px solid #eee;
		}
	}
}

//Find Suppliers
.suppliers-contents {
	padding: 40px 20px 20px;
	position: relative;
	background-color: #fff;
	margin-bottom: 50px;

	@media all and (max-width: 767px) {
		margin-bottom: 10px;
	}

	.catTitle {
		padding: 0;
		margin: 0;
	}

	.search_wrapper {
		max-width: 300px;
	}

	.row {
		margin-right: -10px;
		margin-left: -10px;

		.col-lg-2 {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.imgWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 5px;

		img {
			max-width: 150px;
		}
	}

	.icon-call-answer {
		background-color: rgba($black, 0.5);
		color: #fff;
		border-radius: 30px;
		padding: 3px;
		width: 16px;
		height: 16px;
		font-size: 8px;
		vertical-align: middle;

		&:before {
			content: "\e829";
			transform: rotate(11deg);
		}
	}

	.mailing {
		.icon-mailing {
			font-size: 10px;
			// margin-right: 5px;
			color: rgba(0, 0, 0, 0.5);
		}
	}
}

.suppliers-company {
	&-wrapper {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;
		padding: 20px;
		// border-bottom: 1px solid #ddd;
		background-color: $white;
		font-size: 18px;

		.suppliers {
			&-phone {
				.icon-call-answer {
					font-size: 10px;
					top: -2px;
					position: relative;
				}

				.soldby-phn {
					color: rgba($black, 1);
				}
			}
		}
	}

	&-name {
		text-align: center;
		font-size: 1.24em;
		line-height: 1.2;
		font-weight: 600;
		color: $black;
		margin-bottom: 4px;

		@media all and (max-width: 767px) {
			font-size: 1em;
			opacity: 0.7;
		}
	}

	&-address {
		margin-bottom: 10px;
		font-size: 16px;
		line-height: 1.25;
		color: rgba($black, 0.8);
		text-align: center;

		@media all and (max-width: 767px) {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.5);
		}
	}

	&-ratings {
		margin-bottom: 10px;
	}
}

.suppliers-contact-person {
	border-top: 2px solid $grey;
	padding: 10px;
	padding-bottom: 5px;

	@media all and (max-width: 767px) {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.suppliers-list {
	border: 1px solid $grey;
	position: relative;
	height: 100%;
	background-color: $grey;
	background-color: $white;
	box-shadow: 0 0 5px 1px #e5e5e8;
	border-radius: 10px;
	overflow: hidden;
	padding-bottom: 15px;

	a {
		text-decoration: none;
		color: inherit;

		@media all and (max-width: 767px) {
			// color: rgba(var(--primary-color), 1);
		}
	}

	&:hover {
		box-shadow: 0 0 10px 10px #e5e5e8;

		.suppliers-distance,
		.view-on-map {
			border: 1px solid rgba(var(--primary-color), 1);
			color: rgba(var(--primary-color), 1);
		}

		.suppliers-distance a,
		.view-on-map a {
			color: rgba(var(--primary-color), 1);
		}
	}

	.soldby_icon {
		//padding-left: 24px;
		position: relative;
		word-break: break-word;

		.icon {
			display: inline-flex;
			height: 100%;
			width: 20px;
			align-items: center;
			flex: 0 0 20px;

			&-user,
			&-call-answer,
			&-mailing {
				&:before {
					margin: 0;
				}
			}
		}
	}

	&-wrapper {
		margin-bottom: 20px;

		@media all and (min-width: 992px) {
			max-width: 33.33333333%;
			flex: 33.33333333%;
		}

		@media all and (min-width: 1200px) {
			max-width: 20%;
			flex: 0 0 20%;
		}

		@media all and (min-width: 1600px) {
			max-width: 20%;
			flex: 0 0 20%;
		}
	}
}

.suppliers-top {
	&.no-logo,
	&.suppliers-logo {
		justify-content: center;
		display: flex;
		padding-top: 15px;
		background-color: $white;
		margin-bottom: -5px;
	}

	.varified {
		font-size: 13px;

		img {
			margin-right: 5px;
		}
	}
}

.suppliers .product {
	&-name {
		font-size: 18px;
	}
}

.suppliers-address {
	font-size: 14px;
	font-weight: 300;
	line-height: 1.15;
	color: $black1;
	padding-top: 5px;
	display: flex;
	flex-direction: column;
	margin-bottom: 8px;
	text-align: center;

	.core-address {
		+ a {
			margin-top: 8px;
		}
	}
}

.suppliers-phone-div {
	display: flex;
}

.suppliers-phone,
.suppliers-contact-person {
	font-size: 14px;
	color: rgba($black, 1);

	.soldby_icon {
		//padding-left: 24px;
		position: relative;
		word-break: break-word;

		.icon {
			&-user,
			&-call-answer,
			&-mailing {
				&:before {
					margin: 0;
				}
			}

			&-call-answer {
				background-color: rgba($black, 0.5);
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		&.soldby-seller {
			font-size: 14px;
			margin-bottom: 4px;
			display: flex;
		}

		&.soldby-phn,
		&.mailing {
			line-height: 1.1;
			margin-top: 6px;
			margin-bottom: 4px;
		}

		&.mailing {
			a:hover {
				color: rgba(0, 0, 0, 1);
			}
		}
	}

	.icon {
		.icon-call-answer {
			padding: 3px 4px !important;
		}
	}

	.mailing {
		.icon-mailing {
			font-size: 10px;
		}
	}
}

.soldby_icon-link {
	display: flex;
	align-items: center;
	color: inherit;

	.icon-mailing {
		font-size: 10px;
		// display: inline-flex;
		height: 100%;
		width: 24px;
		align-items: center;
		flex: 0 0 24px;
		color: rgba(0, 0, 0, 0.5);
	}

	.icon-mobile-alt {
		width: 24px;
		align-items: center;
		flex: 0 0 24px;
	}

	&:hover {
		color: inherit;
		text-decoration: none;

		.mailing-text {
			text-decoration: underline;
		}
	}
}

.mailing-text {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.suppliers-header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.suppliers-distance {
	background-color: transparent;
	border: 1px solid rgba(0, 0, 0, 0.3);
	color: rgba(0, 0, 0, 0.3);
	display: inline-block;
	padding: 2px 10px 2px 5px;
	border-radius: 20px;
	font-size: 13px;
	margin: 5px 0;
	white-space: nowrap;

	@media all and (max-width: 767px) {
		border: 1px solid rgba(var(--primary-color), 1);
		color: rgba(var(--primary-color), 1);
	}

	.icon {
		padding-right: 3px;
		font-size: 14px;
		// color: rgba(var(--primary-color), 1);
	}
}

.suppliers-single-page {
	// background: linear-gradient( $grey, $white);

	.suppliers {
		&-list {
			box-shadow: 0 0 5px 1px #e5e5e8;
			margin-bottom: 20px;

			@media all and (max-width: 767px) {
				box-shadow: none;
			}
		}

		&-contents {
			background-color: transparent;
			box-shadow: none;
			margin-top: 0;
			padding-top: 0;
			padding-bottom: 0;
		}

		&-list {
			padding-bottom: 10px;
		}

		&-no-products {
			background-color: $grey;
			margin-bottom: 30px;
			padding: 30px;

			h5 {
				font-weight: 400;
			}
		}
	}

	.col-lg-3 {
		@media all and (min-width: 1400px) {
			max-width: 20%;
			flex: 0 0 20%;
		}
	}

	.col-lg-9 {
		@media all and (min-width: 1400px) {
			max-width: 80%;
			flex: 0 0 80%;
		}
	}
}

.detailscontent {
	.imgWrapper {
		margin-bottom: 5px;
	}
}

.start-ratings {
	// margin-left: 8px;
	display: inline-block;
	vertical-align: text-bottom;
	width: 100%;
}

.sidebar-catlist {
	ul {
		margin: 0;
		padding: 0;
		border: 1px solid #eee;

		li {
			padding: 7px 10px;
			border-bottom: 1px solid #eee;
			font-size: 14px;
			color: #666;
			cursor: pointer;

			&:hover {
				color: $black;
			}

			&.active {
				background-color: rgba(var(--primary-color), 1);
				color: $white;
			}
		}
	}
}

//Questions Answer
.qa {
	&Wrapper {
		padding: 20px;
		padding-top: 10px;
		margin-bottom: 20px;
	}

	&Add {
		p {
			font-weight: 600;
			color: $text-color-3;
		}
	}

	&Input {
		background-color: $white;
		height: 40px;
		border-radius: 4px;

		&box {
			height: 40px;
			border: 1px solid #ddd;
			border-radius: 4px;
			position: relative;

			.btn {
				width: 80px;
				padding: 0;
				position: absolute;
				right: -1px;
				top: -1px;
				height: 40px;
				border-radius: 0 4px 4px 0;
			}
		}
	}

	&Other {
		p {
			font-size: 13px;
		}
	}

	&List {
		border-top: 1px solid #ddd;
		padding-top: 10px;
		position: relative;
		padding-left: 30px;

		&:before {
			content: "Q";
			position: absolute;
			left: 0;
			top: 12px;
			color: #fff;
			font-size: 15px;
			width: 20px;
			height: 20px;
			background-color: #a90a24;
			border-radius: 3px;
			line-height: 1.2;
			text-align: center;
		}
	}

	&Input {
		width: calc(100% - 85px);
		height: 38px;
		border-radius: 4px;
	}

	&Question {
		display: block;
		font-size: 15px;
	}
}

input[type="text"].qaInput {
	width: calc(100% - 85px);
	height: 38px;
	border-radius: 4px 0 0 4px;
}

.question-answer {
	padding-top: 20px;

	p {
		margin-bottom: 5px;
	}
}

.question {
	&by {
		font-size: 12px;
		padding-right: 10px;
		opacity: 0.5;
		position: relative;
		top: -5px;
	}

	&date {
		font-size: 12px;
		opacity: 0.5;
		position: relative;
		top: -5px;
	}
}

.update_date {
	font-size: 12px;
}

.ansList {
	font-size: 14px;
	display: inline-block;
	padding-top: 3px;
	position: relative;
	padding-left: 30px;

	&:before {
		content: "A";
		position: absolute;
		left: 0;
		top: 6px;
		color: #fff;
		width: 20px;
		height: 20px;
		background-color: #999;
		border-radius: 3px;
		line-height: 1.4;
		text-align: center;
	}
}

.answer,
.answerby {
	display: block;
}

.answerdate {
	font-size: 12px;
	opacity: 0.5;
}

.image-gallery {
	&-thumbnail {
		width: 70px !important;
		height: 70px;
		overflow: hidden;
		display: inline-flex !important;
		align-items: center;

		&.active {
			border: 1px solid rgba(var(--primary-color), 1) !important;
		}

		@media all and (max-width: 767px) {
			width: 40px !important;
			height: 40px;
		}
	}

	&-image {
		max-height: 400px;
		text-align: center;
	}

	&-slide {
		img {
			// cursor: pointer;
			cursor: zoom-in;
		}
	}

	&-left-nav {
		left: 0;
		position: absolute;
		width: 50px;
	}

	&-right-nav {
		right: 0;
		position: absolute;
		width: 50px;
	}

	&-left-nav,
	&-right-nav {
		font-size: 3em !important;
	}
}

.blank-item {
	opacity: 0.5;
}

.image-gallery-fullscreen-button::before,
.image-gallery-play-button::before,
.image-gallery-left-nav::before,
.image-gallery-right-nav::before {
	color: $white !important;
	opacity: 0.8;
}

.image-gallery-fullscreen-button:hover::before,
.image-gallery-play-button:hover::before,
.image-gallery-left-nav:hover::before,
.image-gallery-right-nav:hover::before {
	opacity: 1;
	color: $white !important;
}

.image-gallery-fullscreen-button::before,
.image-gallery-play-button::before,
.image-gallery-left-nav::before,
.image-gallery-right-nav::before {
	text-shadow: 0 0 4px #1a1a1a !important;
}

.btnLoader {
	width: 150px;
	height: 35px;
	margin-right: 10px;
	text-align: center;
	opacity: 0.5;
}

@include media-breakpoint-up(lg) {
	.productdetails {
		.showinmobile {
			&.imgWrapper {
				display: none;
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.delivery {
		&options {
			.row {
				.col-sm-8 {
					padding-right: 5px;
				}

				.col-sm-4 {
					padding-left: 5px;

					.soldby-text {
						left: 5px;
					}
				}
			}
		}
	}

	#drawers,
	.showinmobile {
		display: none !important;
	}
}

@include media-breakpoint-down(md) {
	.specification {
		.row {
			margin-left: -5px;
			margin-right: -5px;

			.col-sm-2,
			.col-sm-1 {
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}

	.hideinmobile {
		&.cartproduct-image {
			display: none;
		}
	}

	.cartproduct-image {
		.imgWrapper {
			max-width: 100px;
			margin-bottom: 10px;
		}
	}

	.cart-items {
		.productdetails {
			padding-left: 0;
		}
	}

	.delivery {
		&options {
			.row {
				.col-sm-8,
				.col-sm-4 {
					max-width: 100%;
					flex: 0 0 100%;
				}

				.col-sm-8 {
					order: 2;
					padding-right: 15px;
				}

				.col-sm-4 {
					order: 1;
					padding-left: 15px;
					margin-bottom: 10px;

					.soldby-text {
						left: 15px;
					}
				}
			}
		}
	}
}

@include media-breakpoint-down(md) {
	.thank-msg.whiteBox {
		display: block;
	}

	.singlebtnSection {
		.inputBox {
			width: 90px;
		}

		.btn-outline-danger,
		.btn-danger {
			width: 120px;
		}
	}
}

.blogWrapper {
	.row {
		@media all and (min-width: 1400px) {
			margin-left: -20px;
			margin-right: -20px;
		}
	}

	.col-sm-6 {
		margin-top: 30px;
		margin-bottom: 10px;

		@media all and (min-width: 1400px) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&List {
		.blog-wraper {
			height: 100%;
			background-color: #fff;
			box-shadow: 0 0 18px #e8e6e6;
			display: flex;

			a.link {
				// border: 1px solid #ccc;
				color: #000;

				&:hover {
					text-decoration: none;
				}
			}

			.news {
				&-content {
					padding: 20px 25px;
					font-size: 0.8625rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					flex: 1;

					h2 {
						font-size: 1.5rem;
					}

					&-top {
						.icon-date-wrap {
							opacity: 0.8;
						}

						.user-profile-wrap {
							padding-left: 0;
							padding-right: 15px;

							.icon-user-wrap {
								font-size: 11px;
								width: 18px;
								height: 18px;
								margin-right: 4px;
							}
						}

						.icon-date-wrap {
							padding-right: 3px;
						}
					}

					&-bot {
						margin-top: 20px;
						display: flex;
						justify-content: space-between;
						align-items: baseline;
					}
				}

				&-images {
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					// float: left;
					max-width: 35%;
					flex: 0 0 35%;
				}
			}

			.btn {
				background-color: rgba(var(--secondary-color), 1);
				color: #fff;
				border-radius: 20px;
				padding: 2px 20px;
				font-size: 13px;

				&:hover {
					background-color: rgba(var(--primary-color), 1);
				}
			}

			.blog-link {
				font-size: 10px;
				font-style: italic;
				color: #ccc;
			}
		}
	}
}

.blogDetail {
	.innerContent {
		font-size: 16px;

		.news-images {
			margin-bottom: 20px;
			margin-right: 50px;
			text-align: center;
			border: 1px solid #eee;
			float: left;
			max-width: 30%;
		}
	}

	&--content {
		padding-right: 25%;
	}

	.date-publish {
		font-size: 14px;
		padding-bottom: 10px;
	}

	&s {
		float: left;
		width: 100%;
		margin-bottom: 50px;
	}
}

.validtilldate {
	display: inline-block;

	.smallText {
		color: rgba(var(--primary-color), 1);
		font-weight: 700;
	}
}

.user-date-wrap {
	opacity: 0.8;
	padding-right: 3px;

	.user-profile-wrap {
		padding-left: 0;
		padding-right: 15px;
		font-size: 14px;

		.icon-user-wrap {
			width: 18px;
			height: 18px;
			margin-right: 4px;

			.icon-user {
				font-size: 11px;
			}
		}
	}
}

.relatedDiv {
	float: left;
	width: 100%;
}

.map-popup .popup-content {
	max-width: 650px;
}

.map-popup i.fa.fa-map-marker {
	font-size: 18px;
	// color:rgba(var(--primary-color), 1);
	color: #545766;
}

.map-popup button.button {
	padding: 3px 5px 0 5px;
}

.withmapicon .map-popup {
	display: inline-block;
}

.menu_bar .wrapper_1200 {
	display: inherit;
}

@media (max-width: 568px) {
	.searchboxfield {
		left: 15px !important;
	}
}

.verified {
	&.small-verified {
		display: flex;
		font-size: 13px;
		align-items: center;
		position: absolute;
		left: 0;
		border-radius: 0;
		top: 20px;
		background: rgba(var(--secondary-color), 0.5);
		text-transform: uppercase;
		font-weight: 400;
		padding-right: 10px;

		&:before {
			content: "";
			width: 0;
			height: 0;
			border-left: 12px solid transparent;
			border-right: 12px solid transparent;
			border-top: 12px solid rgba(var(--secondary-color), 0.8);
			position: absolute;
			right: -13px;
			top: 0;
		}

		&:after {
			content: "";
			width: 0;
			height: 0;
			border-left: 12px solid transparent;
			border-right: 12px solid transparent;
			border-bottom: 12px solid rgba(var(--secondary-color), 0.8);
			position: absolute;
			right: -13px;
			bottom: 0;
		}

		img {
			display: none;
		}
	}

	.verified-div {
		background: rgba(var(--secondary-color), 1);
		color: #fff;
		padding: 2px 10px;
		padding-right: 2px;
		position: relative;
		min-height: 25px;
		display: flex;
		align-items: center;

		&:after {
			content: "";
			width: 0;
			height: 0;
			border-top: 13px solid transparent;
			border-bottom: 12px solid transparent;
			border-left: 13px solid rgba(var(--secondary-color), 1);
			position: absolute;
			right: -12px;
			top: 0;
		}
	}
}

.at-right-side {
	&.verified {
		left: auto;
		right: 0;
		padding-right: 0;
		padding-left: 10px;

		&:before {
			content: "";
			width: 0;
			height: 0;
			border-left: 12px solid transparent;
			border-right: 12px solid transparent;
			border-top: 12px solid rgba(var(--secondary-color), 0.8);
			position: absolute;
			right: auto;
			left: -13px;
		}

		&:after {
			content: "";
			width: 0;
			height: 0;
			border-left: 12px solid transparent;
			border-right: 12px solid transparent;
			border-bottom: 12px solid rgba(var(--secondary-color), 0.8);
			position: absolute;
			right: auto;
			left: -14px;
		}

		.verified-div {
			padding-left: 2px;
			padding-right: 10px;
			min-height: 23px;

			&:after {
				content: "";
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-bottom: 11px solid transparent;
				border-right: 13px solid rgba(var(--secondary-color), 1);
				border-left: none;
				position: absolute;
				right: auto;
				left: -13px;
			}
		}
	}
}

.small-verified .verified-logo {
	max-width: 20px;
	margin-right: 5px;
}

.dealership-certificate {
	display: flex;
	justify-content: flex-start;

	.btn {
		width: auto;
		border-radius: 20px;
		padding: 2px 12px;
	}
}

.suppliers-single-page .suppliers-list {
	//padding-bottom: 0;
	padding-bottom: 20px;
}

.suppliers-single-page .padding_side_15 {
	padding-top: 40px;
}

.single-product .sold-by-wrapper .dealership-certificate {
	margin-top: 15px;
	max-width: 200px;
}

.banner-homepage .InfiniteCarouselArrow {
	background-color: rgba(0, 0, 0, 0.3);
	height: 50px;
	border-radius: 50%;
}

.banner-homepage .InfiniteCarouselArrowPrevIcon {
	position: relative;
	left: -2px;
	top: 3px;
}

.banner-homepage .InfiniteCarouselArrowNextIcon {
	position: relative;
	right: 8px;
	top: 3px;
}

.banner-homepage .InfiniteCarouselArrowIcon {
	padding: 8px;
}

.user-profile-wrap .dropdown-item {
	text-align: left;
}

.user-profile-wrap .dropdown-item[role="button"] {
	width: auto;
}

.user-profile-wrap .dropdown-menu {
	min-width: 8rem;
}

.user-profile-div button {
	padding-bottom: 0;
	padding-top: 0;
}

.PopupBox .select2-container .select2-container-dropdown {
	width: 244px;

	@media all and (min-width: 768px) {
		position: fixed;
	}
}

.PopupBox .select2-container .select2-container-dropdown {
	width: 244px;
	z-index: 1;

	@media all and (min-width: 768px) {
		position: fixed;
	}
}

.suppliers-contents .icon-mobile-alt {
	background-color: transparent;
	color: rgba(0, 0, 0, 0.6);
	position: relative;
	left: -4px;
}

.suppliers-contents .search_wrapper {
	display: flex;

	button {
		border: 1px solid #f5f5f6;
	}

	.search_input {
		&:focus {
			border-color: #eaeaec;
			border-right: none;
			background-color: #fff;

			+ button {
				border: 1px solid #eaeaec;
				background-color: #fff;
			}
		}
	}
}

.suppliers-top {
	.verified-logo {
		max-width: 20px;
	}
}

.suppliers-contents.col-lg-3.col-sm-4 {
	.verified.small-verified {
		top: 5px;
	}
}

.select2-container {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	margin: 0;
	position: relative;
	vertical-align: middle;
	width: 100%;
}

.select2-container .select2-container-dropdown {
	position: absolute;
}

.select2-container .select2-selection--single {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	height: 28px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
	display: block;
	padding-left: 8px;
	padding-right: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
	position: relative;
}

.select2-container .select2-selection--multiple {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	min-height: 32px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
	display: inline-block;
	overflow: hidden;
	padding-left: 8px;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.select2-container .select2-search--inline {
	float: left;
}

.select2-container .select2-search--inline .select2-search__field {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border: none;
	font-size: 100%;
	margin-top: 5px;
	padding: 0;
}

.select2-container
	.select2-search--inline
	.select2-search__field::-webkit-search-cancel-button {
	-webkit-appearance: none;
}
.select2-dropdown {
	background-color: #fff;
	border: 1px solid #aaa;
	border-radius: 4px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: block;
	position: absolute;
	left: -100000px;
	width: 100%;
	z-index: 1051;
}
.select2-results {
	display: block;
}
.select2-results__options {
	list-style: none;
	margin: 0;
	padding: 0;
}
.select2-results__option {
	padding: 6px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.select2-results__option[aria-selected] {
	cursor: pointer;
}
.select2-container--open .select2-dropdown {
	left: 0;
}
.select2-container--open .select2-dropdown--above {
	border-bottom: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.select2-container--open .select2-dropdown--below {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.select2-search--dropdown {
	display: block;
	padding: 4px;
}
.select2-search--dropdown .select2-search__field {
	padding: 4px;
	width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
	-webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
	display: none;
}
.select2-close-mask {
	border: 0;
	margin: 0;
	padding: 0;
	display: block;
	position: fixed;
	left: 0;
	top: 0;
	min-height: 100%;
	min-width: 100%;
	height: auto;
	width: auto;
	opacity: 0;
	z-index: 99;
	background-color: #fff;
}
.select2-hidden-accessible {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
}
.select2-container--default .select2-selection--single {
	background-color: #fff;
	border: 1px solid #aaa;
	border-radius: 4px;
}
.select2-container--default
	.select2-selection--single
	.select2-selection__rendered {
	color: #444;
	line-height: 28px;
}
.select2-container--default
	.select2-selection--single
	.select2-selection__clear {
	cursor: pointer;
	float: right;
	font-weight: 700;
}
.select2-container--default
	.select2-selection--single
	.select2-selection__placeholder {
	color: #999;
}
.select2-container--default
	.select2-selection--single
	.select2-selection__placeholder__option {
	display: none;
}
.select2-container--default
	.select2-selection--single
	.select2-selection__arrow {
	height: 26px;
	position: absolute;
	top: 1px;
	right: 1px;
	width: 20px;
}
.select2-container--default
	.select2-selection--single
	.select2-selection__arrow
	b {
	border-color: #888 transparent;
	border-style: solid;
	border-width: 5px 4px 0;
	height: 0;
	left: 50%;
	margin-left: -4px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}
.select2-container--default.select2-container--disabled
	.select2-selection--single {
	background-color: #eee;
	cursor: default;
}
.select2-container--default.select2-container--disabled
	.select2-selection--single
	.select2-selection__clear {
	display: none;
}
.select2-container--default.select2-container--open
	.select2-selection--single
	.select2-selection__arrow
	b {
	border-color: transparent transparent #888;
	border-width: 0 4px 5px;
}
.select2-container--default .select2-selection--multiple {
	background-color: #fff;
	border: 1px solid #aaa;
	border-radius: 4px;
	cursor: text;
}
.select2-container--default
	.select2-selection--multiple
	.select2-selection__rendered {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	list-style: none;
	margin: 0;
	padding: 0 5px;
	width: 100%;
}
.select2-container--default
	.select2-selection--multiple
	.select2-selection__rendered
	li {
	list-style: none;
}
.select2-container--default
	.select2-selection--multiple
	.select2-selection__placeholder {
	color: #999;
	margin-top: 5px;
	float: left;
}
.select2-container--default
	.select2-selection--multiple
	.select2-selection__placeholder__option {
	display: none;
}
.select2-container--default
	.select2-selection--multiple
	.select2-selection__clear {
	cursor: pointer;
	float: right;
	font-weight: 700;
	margin-top: 5px;
	margin-right: 10px;
}
.select2-container--default
	.select2-selection--multiple
	.select2-selection__choice {
	background-color: #e4e4e4;
	border: 1px solid #aaa;
	border-radius: 4px;
	cursor: default;
	float: left;
	margin-right: 5px;
	margin-top: 5px;
	padding: 0 5px;
}
.select2-container--default
	.select2-selection--multiple
	.select2-selection__choice__remove {
	color: #999;
	cursor: pointer;
	display: inline-block;
	font-weight: 700;
	margin-right: 2px;
}
.select2-container--default
	.select2-selection--multiple
	.select2-selection__choice__remove:hover {
	color: #333;
}
.select2-container--default.select2-container--focused
	.select2-selection--multiple {
	border: solid #000 1px;
	outline: 0;
}
.select2-container--default:not(.select2-container--open)
	.select2-focused
	.select2-selection--multiple,
.select2-container--default:not(.select2-container--open)
	.select2-focused
	.select2-selection--single {
	border: solid #000 1px;
	outline: 0;
}
.select2-container--default.select2-container--disabled
	.select2-selection--multiple {
	background-color: #eee;
	cursor: default;
}
.select2-container--default.select2-container--disabled
	.select2-selection__choice__remove {
	display: none;
}
.select2-container--default.select2-container--open.select2-container--above
	.select2-selection--multiple,
.select2-container--default.select2-container--open.select2-container--above
	.select2-selection--single {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below
	.select2-selection--multiple,
.select2-container--default.select2-container--open.select2-container--below
	.select2-selection--single {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
	border: 1px solid #aaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
	background: 0 0;
	border: none;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-appearance: textfield;
}
.select2-container--default .select2-results > .select2-results__options {
	max-height: 200px;
	overflow-y: auto;
}
.select2-container--default .select2-results__option[role="group"] {
	padding: 0;
}
.select2-container--default .select2-results__option[aria-disabled="true"] {
	color: #999;
}
.select2-container--default .select2-results__option[aria-selected="true"] {
	background-color: #ddd;
}
.select2-container--default .select2-results__option .select2-results__option {
	padding-left: 1em;
}
.select2-container--default
	.select2-results__option
	.select2-results__option
	.select2-results__group {
	padding-left: 0;
}
.select2-container--default
	.select2-results__option
	.select2-results__option
	.select2-results__option {
	margin-left: -1em;
	padding-left: 2em;
}
.select2-container--default
	.select2-results__option
	.select2-results__option
	.select2-results__option
	.select2-results__option {
	margin-left: -2em;
	padding-left: 3em;
}
.select2-container--default
	.select2-results__option
	.select2-results__option
	.select2-results__option
	.select2-results__option
	.select2-results__option {
	margin-left: -3em;
	padding-left: 4em;
}
.select2-container--default
	.select2-results__option
	.select2-results__option
	.select2-results__option
	.select2-results__option
	.select2-results__option
	.select2-results__option {
	margin-left: -4em;
	padding-left: 5em;
}
.select2-container--default
	.select2-results__option
	.select2-results__option
	.select2-results__option
	.select2-results__option
	.select2-results__option
	.select2-results__option
	.select2-results__option {
	margin-left: -5em;
	padding-left: 6em;
}
.select2-container--default
	.select2-results__option--highlighted[aria-selected] {
	background-color: #5897fb;
	color: #fff;
}
.select2-container--default .select2-results__group {
	cursor: default;
	display: block;
	padding: 6px;
	color: gray;
}
select2.material {
	display: inline-block;
	width: 300px;
}
select2.material > .select2-container {
	padding-bottom: 1.29688em;
	vertical-align: inherit;
}
select2.material > .select2-container .selection {
	padding: 0.4375em 0;
	border-top: 0.84375em solid transparent;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: baseline;
	-ms-flex-align: baseline;
	align-items: baseline;
	width: 100%;
	height: auto;
}
select2.material .select2-container--default .select2-selection--multiple,
select2.material .select2-container--default .select2-selection--single {
	width: 100%;
	border: 0;
	border-radius: 0;
	height: 24px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
select2.material
	.select2-container--default
	.select2-selection--multiple::before,
select2.material
	.select2-container--default
	.select2-selection--single::before {
	content: " ";
	display: block;
	position: absolute;
	bottom: 1.65em;
	background-color: #ddd;
	height: 1px;
	width: 100%;
}
select2.material
	.select2-container--default
	.select2-selection--multiple::after,
select2.material .select2-container--default .select2-selection--single::after {
	content: " ";
	display: block;
	position: absolute;
	bottom: 1.63em;
	background-color: #5a419e;
	height: 2px;
	width: 0%;
	left: 50%;
	-webkit-transition: none;
	transition: none;
}
select2.material
	.select2-container--default
	.select2-selection--multiple
	.select2-selection__rendered,
select2.material
	.select2-container--default
	.select2-selection--single
	.select2-selection__rendered {
	padding-left: 1px;
	line-height: inherit;
}
select2.material
	.select2-container--default
	.select2-selection--multiple
	.select2-selection__placeholder,
select2.material
	.select2-container--default
	.select2-selection--single
	.select2-selection__placeholder {
	display: block;
	color: rgba(0, 0, 0, 0.38);
	-webkit-transition: -webkit-transform 0.3s;
	transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	transition: transform 0.3s, -webkit-transform 0.3s;
	position: absolute;
	-webkit-transform-origin: 0 21px;
	transform-origin: 0 21px;
	left: 0;
	top: 20px;
}
select2.material .select2-container--default .select2-container--open {
	left: 0;
	bottom: 1.6em;
}
select2.material
	.select2-container--default
	.select2-selection__placeholder__option {
	-webkit-transform: translateY(-1.5em) scale(0.75) perspective(100px)
		translateZ(0.001px);
	transform: translateY(-1.5em) scale(0.75) perspective(100px)
		translateZ(0.001px);
	width: 133.33333%;
}
select2.material .select2-container--default .select2-selection__arrow {
	top: 20px;
}
select2.material
	.select2-container--default
	.select2-focused
	.select2-selection--multiple::after,
select2.material
	.select2-container--default
	.select2-focused
	.select2-selection--single::after,
select2.material
	.select2-container--default.select2-container--open
	.select2-selection--multiple::after,
select2.material
	.select2-container--default.select2-container--open
	.select2-selection--single::after {
	-webkit-transition: width 0.3s cubic-bezier(0.12, 1, 0.77, 1),
		left 0.3s cubic-bezier(0.12, 1, 0.77, 1);
	transition: width 0.3s cubic-bezier(0.12, 1, 0.77, 1),
		left 0.3s cubic-bezier(0.12, 1, 0.77, 1);
	width: 100%;
	left: 0;
}
select2.material .select2-container--default .select2-dropdown {
	border-radius: 0;
	border: 0;
	-webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
}
select2.material
	.select2-container--default
	.select2-results__option--highlighted[aria-selected],
select2.material
	.select2-container--default
	.select2-results__option[aria-selected="true"] {
	background-color: rgba(0, 0, 0, 0.04);
	color: #000;
}
select2.material
	.select2-container--default
	.select2-results__option[aria-selected="true"] {
	color: #ff5722;
}
select2.material
	.select2-container--default.select2-container--disabled
	.select2-selection--multiple,
select2.material
	.select2-container--default.select2-container--disabled
	.select2-selection--single {
	background-color: transparent;
}
select2.material
	.select2-container--default.select2-container--disabled
	.select2-selection--multiple::before,
select2.material
	.select2-container--default.select2-container--disabled
	.select2-selection--single::before {
	background-image: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0, rgba(0, 0, 0, 0.26)),
		color-stop(33%, rgba(0, 0, 0, 0.26)),
		color-stop(0, transparent)
	);
	background-image: linear-gradient(
		to right,
		rgba(0, 0, 0, 0.26) 0,
		rgba(0, 0, 0, 0.26) 33%,
		transparent 0
	);
	background-size: 4px 1px;
	background-repeat: repeat-x;
	background-color: transparent;
	background-position: 0 bottom;
}
select2.material.ng-invalid.ng-touched
	.select2-container--default
	.select2-selection--multiple::after,
select2.material.ng-invalid.ng-touched
	.select2-container--default
	.select2-selection--multiple::before,
select2.material.ng-invalid.ng-touched
	.select2-container--default
	.select2-selection--single::after,
select2.material.ng-invalid.ng-touched
	.select2-container--default
	.select2-selection--single::before {
	background-color: red;
}
select2.material:not(.select2-container--open)
	.select2-focused
	.select2-selection--multiple,
select2.material:not(.select2-container--open)
	.select2-focused
	.select2-selection--single {
	border: 0;
}
select2.material .select2-subscript-wrapper {
	position: absolute;
	top: calc(100% - 1.72917em);
	font-size: 75%;
}
@supports (-moz-appearance: none) {
	select2.material .select2-container--default .select2-selection--multiple,
	select2.material .select2-container--default .select2-selection--single {
		height: 26px;
	}
}
@supports (-ms-scroll-limit: 0) {
	select2.material .select2-container--default .select2-selection--multiple,
	select2.material .select2-container--default .select2-selection--single {
		height: 25px;
	}
}

.appOpenBlock {
	font-size: 14px;
	line-height: 1.2;
	font-family: Nunito, sans-serif;
	font-weight: 800;
	color: #666;
	display: flex;
	justify-content: space-between;
	align-items: center;

	img {
		margin-right: 15px;
		display: block;
		margin-bottom: 5px;
		margin-top: 5px;
		float: left;
	}

	&Left {
		display: flex;
		align-items: center;
	}
}

.appBlockLogo {
	width: 45px !important;
}

a#appLink {
	padding: 1.5px 5px;
	background-color: #439b3c;
	border: 1px solid #439b3c;
	border-radius: 5px;
	text-decoration: none;
	font-size: 11px;
	color: #fff;
	font-family: Nunito, sans-serif;
	font-weight: 400;
	line-height: 23px;
	height: 27px;
}

.cookieConsent {
	border-bottom: 1px solid #ccc;
	box-shadow: 0 0 5px #ccc;

	button {
		padding: 2px 3px 0 !important;
		margin: 13px 8px 0 !important;
		font-size: 13px;
		width: 25px !important;
		height: 25px;
		position: absolute;
		left: -2px;
		top: 3px;
		text-align: center;
		font-family: Nunito, sans-serif;
		border-radius: 30px !important;
		background: #ca3f3f !important;
		color: #fff !important;
	}

	> div {
		margin: 12px 10px 10px !important;
		padding-left: 30px;

		.smallText {
			font-size: 12px;
			display: inline-block;
			padding-top: 3px;
		}
	}

	+ .mainWrapper {
		padding-top: 65px;

		.navbar,
		#drawers {
			top: 65px;
		}

		@media all and (max-width: 767px) {
			.map-popup .popup-content {
				margin-top: 70px !important;
			}
		}
	}
}

.mrg-lft-20 {
	margin-left: 20px;
}

.PopupBox {
	.select2-container {
		.select2-selection--single {
			height: 38px;
		}

		.select2-container-dropdown {
			@media all and (max-width: 767px) {
				width: 100%;
			}
		}
	}

	.select2-container--default {
		.select2-selection--single {
			background-color: #fff;
			border: 1px solid #cac5c5;
			border-radius: 4px;

			.select2-selection__rendered {
				padding-top: 3px;
			}

			.select2-selection__arrow {
				top: 5px;
			}
		}
	}
}

.cartPage .popup-content .modalBody {
	@media all and (min-width: 768px) {
		overflow: inherit;
	}

	@media all and (max-width: 767px) {
		.btnWrapper {
			margin-bottom: 100px;
			margin-top: 0;
		}
	}
}

.menu_bar a.link.active {
	color: rgba(var(--primary-color), 1);
}

.suppliers-list .dealership-certificate {
	justify-content: center;
}

.cta-btn {
	padding: 4px 12px 4px 8px;
	background-color: #888;
	border: 1px solid #888;
	border-radius: 20px;
	text-decoration: none;
	font-size: 13px;
	font-family: Nunito, sans-serif;
	font-weight: 400;
	color: #fff !important;
	width: auto;
	z-index: 2;
	position: relative;
	transition: all 0.3s linear;

	&:after {
		background: rgba(var(--primary-color), 1);
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		opacity: 0;
		transform: scale(0.1, 1);
		z-index: -1;
		border-radius: 25px;
		transition: all 0.4s ease;
	}

	&:hover {
		background-color: rgba(var(--primary-color), 1);
		border: 1px solid rgba(var(--primary-color), 1);

		&:after {
			opacity: 1;
			transform: scale(1, 1);
		}
	}

	&:hover {
		color: #fff !important;

		.icon .icon {
			color: #fff !important;
		}
	}
}

.cta-btn:active,
.cta-btn:focus {
	box-shadow: inset 100px 100px 10px rgba(0, 0, 0, 0.4);
}

.call-suppliers-btn {
	display: flex;
	justify-content: space-between;
}

.call-suppliers-btn .icon .icon {
	color: #fff !important;
}

.call-suppliers-btn .icon .icon:before {
	margin-right: 0;
	margin-left: 2px;
}

.call-suppliers-btn .icon .icon.icon-mailing {
	font-size: 9px;
	position: relative;
	left: -4px;
	top: -2px;
}

.call-suppliers-btn .icon .icon.icon-mailing:before {
	margin-left: 5px;
	margin-right: 5px;
}

.call_list_div {
	display: flex;

	.click-to-call {
		margin-right: 5px;
		display: inline-block;

		&:after {
			content: ",";
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	span.icon {
		width: 20px;
		display: inline-flex;
		flex: 0 0 20px;
	}
}

.single-product .call-suppliers-btn {
	margin-bottom: 0;
}

a.click-to-call.email-mobile.cta-btn,
a.click-to-call.call-mobile.cta-btn {
	pointer-events: auto;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.suppliers-contact-person .email-mobile-hide {
	width: auto;
	pointer-events: none;
	background: none;
}

.suppliers-phone .soldby-phn a span.icon-call-answer {
	margin-right: 5px;
}

span.updatedaysText {
	display: block;
	font-size: 14px;
	padding: 8px 0;
	color: rgba(var(--primary-color), 1);
}

.socialShareWrap {
	padding-top: 5px;
	padding-bottom: 5px;
	margin: 0 auto;
	font-size: 14px;
	max-width: calc(96% - 30px);

	@media all and (min-width: 768px) {
		padding-top: 12px;
		margin-top: 10px;
		border-top: 1px solid #eff0f5;
	}

	.react-share__ShareButton {
		transition: all 0.3s linear;

		&:hover {
			transform: translateY(-5px);
		}
	}

	.social-text {
		display: block;
		padding-bottom: 5px;
		padding-right: 5px;
	}

	button.react-share__ShareButton {
		width: 26px;
		margin-right: 5px;

		@media all and (max-width: 1130px) {
			margin: 0 1px;
		}

		svg {
			width: 100%;
			height: 100%;
		}
	}

	.brandWrapper & {
		text-align: left;
		padding: 0;
		margin-top: 10px;
	}

	.aboutwrap &,
	.blogWrapper & {
		border-top: 1px solid $grey;
	}

	.width260 &,
	.category_wrapper .suppliers-contents & {
		padding-left: 10px;
		padding-right: 10px;
		margin-bottom: -10px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: flex-start;
		width: 100%;

		@media all and (max-width: 1024px) {
			flex-wrap: wrap;
		}

		.social-text {
			@media all and (max-width: 1024px) {
				flex: 100%;
				max-width: 100%;
				width: 100%;
			}
		}
	}

	.category_wrapper .suppliers-contents & {
		button.react-share__ShareButton {
			@media all and (min-width: 576px) and (max-width: 1024px) {
				width: calc(25% - 10px);
			}
		}
	}

	.width260 & {
		padding-left: 0;
		padding-right: 0;

		button.react-share__ShareButton {
			@media all and (min-width: 768px) and (max-width: 1024px) {
				margin: 0 2px;
				width: calc(25% - 10px);
			}
		}
	}
}

.innerContent .socialShareWrap {
	text-align: left;
}

.deliveryoptions {
	.nav-tabs {
		padding-left: 10px;
		padding-top: 10px;

		@media all and (min-width: 768px) {
			border-top: 5px solid #fff;
		}

		.nav-link {
			font-size: 0.85rem;
			color: var(--dark);
			opacity: 0.5;

			&.active {
				background-color: #fafafa;
				border-bottom-color: #fafafa;
				color: rgba(var(--primary-color), 1);
				opacity: 1;
			}
		}
	}

	.warranty-text {
		font-weight: 400;
	}

	.link {
		color: rgba(var(--primary-color), 1);

		&:hover {
			text-decoration: none;
			color: #000;
		}
	}

	.proDistance {
		cursor: pointer;

		a {
			color: #888;

			@media all and (max-width: 767px) {
				color: rgba(var(--primary-color), 1);
			}
		}

		&:hover {
			border-color: rgba(var(--primary-color), 1);

			a {
				color: rgba(var(--primary-color), 1);
				text-decoration: none;
			}
		}
	}

	.sold-by-wrapper {
		.socialLink {
			justify-content: flex-start;
			margin-bottom: 0;
			padding-bottom: 0;
		}

		.verified .verified-div {
			min-height: 23px;
		}
	}
}

.single-product .tab-content .deliveryoptions-wrapper {
	border-top-color: #fafafa;
}

.product-meta-wrapper {
	.col-md-6 .row {
		height: 100%;
	}

	.sold-by-wrapper {
		.call-suppliers-btn {
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 10px;
		}

		.socialLink {
			margin-bottom: 10px;

			&-icon {
				span {
					margin: 0;
					margin-left: -2px;
				}

				&-facebook {
					span {
						margin: 0;
					}
				}
			}
		}

		.at-right-side {
			right: 14px;
			top: 5px;
		}
	}

	.image-gallery {
		@media all and (max-width: 767px) {
			padding: 0;
			margin-left: 0;
		}
	}
}

.socialLink {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 10px;

	&-icon {
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: transform 0.2s ease;
		text-decoration: none;

		&:hover {
			text-decoration: none;
			transform: translateY(-2px);
		}

		span {
			font-size: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 2px;
			margin-right: 2px;

			&:before {
				margin: 0;
			}
		}

		&-facebook {
			span {
				font-size: 18px;
				color: #3b5998;
				margin-left: 8px;
			}
		}

		&-instagram {
			span {
				color: #e1306c;
			}
		}

		&-twitter {
			span {
				color: #1da1f2;
			}
		}
	}
}

.product-description {
	> .row {
		margin-top: 0 !important;

		> .col-lg-8 {
			@media all and (min-width: 768px) {
				padding-right: 5px;
			}
		}
	}
}

/*list price*/
.list-wrapper .list.premium-list .item {
	width: calc(50% - 20px);
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
}

.list-wrapper .list.premium-list .item-image {
	min-height: 250px;
	width: 55%;
}

.list-wrapper .list.premium-list .item-image img {
	height: 100%;
	width: auto;
}

.list-wrapper .list.premium-list .item-text {
	width: 45%;
}

.list-wrapper.fullwidth .list.premium-list .item-image {
	width: 60%;
}

.list-wrapper.fullwidth .list.premium-list .item-image img {
	height: auto;
	width: 100%;
}

.list-wrapper.fullwidth .list.premium-list .item-text {
	width: 40%;
}

@media all and (max-width: 1199px) {
	.premium-list .item-image,
	.premium-list .item-text {
		width: 50%;
	}

	.premium-list .item-image img {
		height: 100%;
		width: auto;
	}
}

@media all and (max-width: 1149px) {
	.list-wrapper .list.premium-list .item {
		width: 100%;
	}

	.list-wrapper .list.premium-list .item-image img {
		width: 100%;
		height: auto;
	}

	.list-wrapper.fullwidth .list.premium-list .item {
		width: calc(50% - 20px);
	}
}

@media all and (max-width: 1023px) {
	.list-wrapper.fullwidth .list.premium-list .item {
		width: 100%;
	}
}

@media all and (max-width: 639px) {
	.list-wrapper .list.premium-list .item {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		display: block;
	}

	.list-wrapper .list.premium-list .item-image,
	.list-wrapper .list.premium-list .item-text {
		width: 100% !important;
		display: block;
	}

	.list-wrapper .list.premium-list .item-image img,
	.list-wrapper .list.premium-list .item-text img {
		position: static;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		height: auto;
		min-height: 0;
	}

	.descriptionSpecification .qaInputbox input[type="text"]::placeholder,
	.descriptionSpecification
		.qaInputbox
		input[type="text"]::-webkit-input-placeholder {
		font-size: 11px;
	}
}

@media all and (max-width: 479px) {
	.premium-list .item {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.premium-list .item-text {
		min-height: initial;
	}

	.premium-list .item-image,
	.premium-list .item-text {
		width: 100%;
	}

	.premium-list .item-image img {
		height: auto;
		width: 100%;
	}
}

.pricing-page {
	padding: 20px 0;
}

.pricing-page > .container {
	padding-left: 0;
	padding-right: 0;
	max-width: 100%;
}

.price-list-wrap {
	margin: 0 auto;
	padding: 40px 15px;
}

.price-list-wrap .price-list > div {
	padding: 0;
	margin-right: -1px;
}

.price-list-wrap .price-wrap {
	text-align: center;
}

.price-list-wrap .price-wrap .title {
	padding: 15px 5px;
	margin-bottom: 0;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 16px;
	color: #fff;
}

.price-list-wrap .price-wrap .title h3 {
	margin-bottom: 0;
	color: #fff;
}

.price-list-wrap .price-wrap .amount-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	height: 100px;
	font-weight: 600;
	-webkit-box-shadow: inset 0 0 80px 10px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 80px 10px rgba(0, 0, 0, 0.3);
}

.price-list-wrap .price-wrap .amount-wrap .small {
	font-size: 20px;
	padding: 14px 5px 0;
}

.price-list-wrap .price-wrap .amount-wrap .big {
	font-size: 40px;
}

.price-list-wrap .price-wrap ul li {
	padding: 10px;
	font-size: 16px;
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}

.price-list-wrap .price-wrap ul li:before {
	content: none;
}

.price-list-wrap .price-wrap.orange .amount-wrap,
.price-list-wrap .price-wrap.orange .title {
	background-color: #fdc730;
}

.price-list-wrap .price-wrap.orange .high-light {
	background-color: rgba(253, 199, 48, 0.2);
}

.price-list-wrap .price-wrap.orange ul li {
	border-left: 1px solid rgba(253, 199, 48, 0.5);
	border-right: 1px solid rgba(253, 199, 48, 0.5);
	border-bottom: 1px solid rgba(253, 199, 48, 0.5);
}

.price-list-wrap .price-wrap.green .title {
	background-color: rgba(0, 161, 57, 0.8);
}

.price-list-wrap .price-wrap.green .amount-wrap {
	background-color: #00a139;
}

.price-list-wrap .price-wrap.green .high-light {
	background-color: rgba(0, 161, 57, 0.1);
}

.price-list-wrap .price-wrap.green ul li {
	border-left: 1px solid rgba(0, 161, 57, 0.4);
	border-right: 1px solid rgba(0, 161, 57, 0.4);
	border-bottom: 1px solid rgba(0, 161, 57, 0.4);
}

.price-list-wrap .price-wrap.blue .title {
	background-color: rgba(0, 89, 159, 0.8);
}

.price-list-wrap .price-wrap.blue .amount-wrap {
	background-color: #00599f;
}

.price-list-wrap .price-wrap.blue .high-light {
	background-color: rgba(0, 89, 159, 0.1);
}

.price-list-wrap .price-wrap.blue ul li {
	border-left: 1px solid rgba(0, 89, 159, 0.4);
	border-right: 1px solid rgba(0, 89, 159, 0.4);
	border-bottom: 1px solid rgba(0, 89, 159, 0.4);
}

.price-list-wrap .price-wrap.red .title {
	background-color: rgba(228, 0, 43, 0.8);
}

.price-list-wrap .price-wrap.red .amount-wrap {
	background-color: $gharBazarRed;
}

.price-list-wrap .price-wrap.red .high-light {
	background-color: rgba(228, 0, 43, 0.08);
}

.price-list-wrap .price-wrap.red ul li {
	border-left: 1px solid rgba(228, 0, 43, 0.3);
	border-right: 1px solid rgba(228, 0, 43, 0.3);
	border-bottom: 1px solid rgba(228, 0, 43, 0.3);
}

.termsPage .wrapper_1200.long-wrapper {
	padding-right: 15px;
}

.suppliers-company-wrapper {
	padding-bottom: 10px;
}

.strike-wrap {
	text-align: left;
	flex: 0.2;
}

.price-list-wrap .price-wrap .amount-wrap .big.strike-through {
	text-decoration: line-through;
	font-size: 26px !important;
	line-height: 1;
	opacity: 0.8;
}

.price-list-wrap .price-wrap .amount-wrap .strike-through + .big {
	line-height: 1;
}

.section-header a#becomeaseller {
	background-color: rgba(var(--secondary-color), 1);
	color: #fff;
	cursor: pointer;
	display: inline-flex;
	padding: 5px 20px;
	border: none;
	border-radius: 25px;
	position: relative;
	z-index: 2;
	text-transform: uppercase;
	font-size: 14px;
}

.section-header a#becomeaseller:after {
	background: rgba(var(--primary-color), 1);
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	transform: scale(0.1, 1);
	z-index: -1;
	border-radius: 25px;
	transition: all 0.4s ease;
}

.section-header a#becomeaseller:hover {
	border: none;
	text-decoration: none;

	&:after {
		opacity: 1;
		transform: scale(1, 1);
	}
}

.section-header #drawers a#becomeaseller {
	margin-left: 15px;
	margin-top: 10px;
}

.suppliers {
	&-list {
		display: flex;
		flex-direction: column;

		.imgWrapper {
			height: 100px;
			width: 100%;

			img {
				max-height: 100%;
				max-width: 100%;
			}
		}

		.dealership-certificate {
			margin-bottom: 10px;
		}

		.suppliers {
			&-company-wrapper {
				padding-top: 20px;
			}
		}

		.top-section {
			flex: 1;
			position: relative;
			padding-top: 44px;

			@media all and (max-width: 676px) {
				padding-top: 0;
			}

			.suppliers-top {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
			}
		}

		.single-btn {
			justify-content: center;
		}
	}

	&-single-page {
		.socialLink {
			margin-top: 5px;
		}
	}
}

.swiper {
	&-container {
		max-width: 100%;

		.SwiperBanner {
			display: flex;

			.swiper {
				&-slide {
					padding: 0;
					height: auto;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					min-height: 500px;

					@media all and (max-width: 992px) {
						min-height: 300px;
					}

					@media all and (max-width: 575px) {
						min-height: 200px;
					}

					img {
						opacity: 0;
					}
				}
			}

			~ .swiper {
				&-button-white {
					background-color: rgba(0, 0, 0, 0.3);
					width: 50px;
					height: 50px;
					border-radius: 50%;
					background-image: none !important;

					&.swiper-button {
						&-next {
							padding-left: 5px;
						}

						&-prev {
							padding-right: 5px;
						}
					}

					&:after {
						font-size: 30px;
						text-shadow: 0 0 2px rgba($color: $white, $alpha: 1);
					}

					@media all and (max-width: 575px) {
						display: none;
					}
				}
			}
		}
	}
}

.banner-homepage {
	.swiper {
		&-container {
			.SwiperBanner {
				.swiper {
					&-slide {
						min-height: 0;
						background-image: none !important;
						display: flex;
						align-items: center;
						justify-content: center;

						img {
							opacity: 1;
							width: 100%;
						}
					}
				}
			}
		}
	}
}

.single-product .sold-by-wrapper .dealership-certificate {
	margin-bottom: 15px;
	margin-top: 5px;
}

.supplier {
	&-content-section {
		@media all and (min-width: 992px) {
			padding-right: 10%;
		}

		p {
			font-size: 0.9375rem;
			font-weight: 300;
			color: #000 !important;
			margin-bottom: 1rem !important;
		}

		h1,
		h2,
		h3,
		h4 {
			color: #000 !important;
		}
	}

	&-map-section {
		margin-bottom: 20px;
	}
}

.whatsapp-icon {
	position: fixed;
	right: 10px;
	bottom: 10px;
}

.st0 {
	fill: #00e676;
}
.icon_logo_white {
	fill: #ffffff;
}

.supplier-map-section .supplier-content-section {
	margin-bottom: 25px;
	padding-left: 0;
	border: 0;
	overflow: visible;
	flex-wrap: wrap;

	.suppliers-office {
		width: 100%;
		max-width: 100%;
	}

	.contact-details {
		p {
			margin-bottom: 0.25rem !important;
		}
	}

	.suppliers-phone {
		margin-bottom: 20px;
		margin-top: -10px;

		[class*="icon-"] {
			margin-right: 5px;
			width: 16px;
			min-width: 16px;
			display: inline-flex;
		}

		.icon-call-answer {
			align-items: center;
			justify-content: center;
		}

		.icon-globe {
			&:before {
				margin-left: 2px;
			}
		}

		.icon-mailing {
			font-size: 9px;
		}

		a {
			color: rgba($black, 1);
		}
	}

	.contact-details {
		display: inline-flex;
		flex-direction: column;
		border: 1px solid $grey2;
		padding: 10px 20px 15px;
		border-radius: 10px;

		.soldby_icon {
			margin-bottom: 4px;
			margin-top: 6px;

			&:last-child {
				margin-bottom: 0;
				margin-top: 0;
			}
		}

		.icon-mailing {
			font-size: 10px;
		}
	}
}

.suppliers-distance-wrapper {
	font-size: 12px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 10px;
	margin-bottom: 10px;

	.suppliers-distance,
	.view-on-map {
		margin-left: 5px;
		margin-right: 5px;
	}
}

.view-on-map {
	background-color: transparent;
	border: 1px solid rgba(0, 0, 0, 0.3);
	color: rgba(0, 0, 0, 0.3);
	display: inline-block;
	padding: 3px 10px 3px 5px;
	border-radius: 20px;
	font-size: 13px;
	margin: 5px 0;
	white-space: nowrap;

	@media all and (max-width: 767px) {
		border: 1px solid rgba(var(--primary-color), 1);
		color: rgba(var(--primary-color), 1);
	}

	a {
		color: rgba(0, 0, 0, 0.3);

		@media all and (max-width: 767px) {
			color: rgba(var(--primary-color), 1);
		}
	}
}

.SwiperBanner .swiper-lazy-preloader:after {
	content: none;
}

.catTitle {
	text-align: left;
	padding-top: 20px;
	padding-bottom: 20px;
	color: $text-color-4;
	position: relative;
	font-size: 1.8em;
	text-transform: uppercase;
	color: #3e4152;
	font-family: $font-family-second;
	font-weight: 600;
	letter-spacing: 0.15em;

	@media all and (max-width: 767px) {
		font-size: 1.5em;
		margin-bottom: 0;
		font-size: 12px;
		padding-top: 12px;
		padding-bottom: 12px;
		color: #535766;
		letter-spacing: 1.5px;
		font-family: Heebo, sans-serif;
	}

	a {
		color: $text-color-4;
	}
}

ul.autocompletelist {
	padding-left: 0;
	padding-right: 0;
	background-color: #fff;
	margin-top: -2px;
	max-height: 600px;
	overflow: hidden;
	overflow-y: auto;
	position: absolute;
	border-radius: 4px 0 0 4px;
	width: 100%;
	z-index: 10;
	top: 41px;

	@media all and (max-width: 767px) {
		top: 91px;
		left: 0;
	}

	li {
		list-style: none;
		font-size: 13px;
		padding: 6px 10px;
		color: #949090;
		border: 1px solid #f5f5f6;
		border-bottom: none;
		cursor: pointer;

		@media all and (max-width: 767px) {
			padding: 6px 15px;
		}

		&:last-child {
			border-bottom: 1px solid #f5f5f6;
		}

		&:hover {
			color: $black1;
		}
	}
}

.starrating-div {
	@media all and (min-width: 1025px) {
		height: 22px;
	}
}

.find_suppliers {
	.category_wrapper-list {
		.categoryList {
			padding-bottom: 25px;

			.wishlist:hover {
				background-color: rgba(var(--primary-color), 1);

				.icon-heart-black {
					color: $white !important;
				}
			}
		}
	}
}

.userpages {
	height: 100%;

	.nav_right-cart {
		padding-left: 20px;
		padding-right: 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		position: relative;

		.icon-cart {
			display: block;
			font-size: 16px;
			padding: 0;
			position: relative;
			line-height: 1;
		}

		.text-cart {
			font-size: 0.875rem;
			font-weight: 400;
			color: #000;

			&:hover {
				text-decoration: none;
			}
		}

		&:hover {
			text-decoration: none;
		}
	}
}

.soldby-mailing {
	a:hover {
		color: rgba(0, 0, 0, 1);
	}
}

.imgWrapper-as-bg {
	background-size: cover;
	background-position: 50%;

	img {
		opacity: 0;
	}
}

.searchsitelist {
	.pagination_wrapper {
		border-top: 1px solid #eaeaec;
	}
}

.custom-domain {
	.menu_bar .menu-right-section-div {
		margin-right: 0;
	}

	.userpages .nav_right-cart {
		padding-left: 10px;
	}

	.supplierPage .category_wrapper .sidemargin {
		margin-left: -15px;
		margin-right: -15px;
	}

	.category_wrapper .category_wrapper-list .categoryList {
		padding-bottom: 20px;
	}
}

.custom-domain {
	.btn-signFb {
		display: none;

		+ .text-medium {
			display: none;
		}
	}
}

.featured_category,
.show-in {
	&-mobile {
		@media all and (min-width: 768px) {
			display: none !important;
		}

		.catTitle,
		.btnWrapper {
			display: none;
		}
	}

	&-desktop {
		@media all and (max-width: 767px) {
			display: none !important;
		}
	}
}

section.adSection {
	background-color: #f5f0f0;
	padding: 30px 0 10px 0;
}

.googleadSection {
	padding: 0;
}

.show-in-mobile {
	.price {
		font-size: 16px;
		font-weight: 500;
		margin-right: 6px;
		display: inline-block;
		color: #424553;
		font-family: $font-family-heebo;
	}

	.cart-delete {
		position: relative;
		top: auto;
		left: auto;
		color: #696b79;
		opacity: 1;
		padding-left: 40px;
		padding-right: 40px;
		border-right: 1px solid #eaeaec;
	}

	.cartAmnt {
		display: flex;
		justify-content: space-between !important;
	}

	.productdetails {
		display: flex;
		justify-content: space-between;
	}

	.cartproduct-image {
		max-width: 100%;
		margin: 0;
		flex: 1;
	}

	.cartproduct-details {
		flex: 2;

		h3 {
			font-weight: 500;
			font-size: 13px;
			margin-bottom: 0;
			opacity: 0.7;
			font-family: $font-family-heebo;
		}

		.smallText {
			font-size: 12px;
			font-weight: 300;
		}

		.shippingprice {
			color: #000;
			font-weight: 500;
			opacity: 0.7;
			font-size: 12px;
		}

		span.cart-shipname {
			font-weight: 400;
			opacity: 0.5;
			font-size: 11px;
			font-family: "Nunito", sans-serif;
		}

		.cart-shiptext {
			margin-bottom: 10px;
		}

		.quantity {
			max-width: 100px;
			margin-left: 0;
		}
	}
}

.cart-items .show-in-mobile .cartAmnt {
	border-top: 1px solid #eff0f5;
	background-color: transparent;
	padding-left: 0 !important;
	padding-right: 0;
	padding-top: 10px;
	padding-bottom: 0;
	font-family: "Nunito", sans-serif;

	.cart-delete,
	.currency {
		font-size: 12px;
		line-height: 20px;
		text-transform: uppercase;
	}

	.currency {
		padding-right: 40px;
	}
}

//media css

@media all and (min-width: 1250px) {
	.wrapper-three-col-footer {
		.row {
			margin-left: -40px;
			margin-right: -40px;

			.col-sm-4 {
				padding-left: 40px;
				padding-right: 40px;
			}
		}
	}
}

@media all and (max-width: 767px) {
	.banner-homepage .InfiniteCarouselArrowIcon {
		border-width: 0 3px 3px 0;
		padding: 5px;
	}
	.banner-homepage .InfiniteCarouselArrow {
		height: 30px;
		width: 30px;
		padding: 0 5px;
	}
	.banner-homepage .InfiniteCarouselArrowPrevIcon {
		position: relative;
		left: 2px;
		top: 2px;
	}
	.banner-homepage .InfiniteCarouselArrowNextIcon {
		position: relative;
		right: 2px;
		top: 2px;
	}
}

@media all and (max-width: 767px) {
	.cartPage {
		.col-12 {
			width: 100%;
			max-width: 100%;
			flex: 0 0 100%;
			margin-bottom: 20px;
		}

		.wrapper {
			background-color: transparent;
			padding-left: 0;
			padding-right: 0;

			.row {
				margin-left: 0;
				margin-right: 0;

				.col-12 {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
	}

	.cartproduct-image {
		max-width: 120px;
		margin-bottom: 10px;
	}

	.thank-msg {
		&.whiteBox {
			flex-direction: column;
		}
	}

	.section_sort.supplier_sort {
		margin-bottom: 10px;
	}

	.single-product {
		.product-description {
			padding: 0;

			> .row {
				margin: 0;

				> .col-md-6 {
					padding: 0;
				}
			}

			.descriptionSpecification {
				padding: 15px;
				margin-bottom: 0;
			}
		}

		.wrapper {
			background-color: transparent;
		}

		.other-related-products {
			margin-top: 10px;

			.por .swiper-container {
				max-width: 100%;
			}
		}
	}

	.question-answer {
		padding-top: 10px;
	}

	.other-related-products {
		margin-top: 10px;
	}

	.category_wrapper-single .breadcrumb-div {
		padding: 0;
		padding-top: 5px;
	}

	.cart-items {
		padding: 10px;
		flex-wrap: wrap;
	}

	.cart-items + .cart-items {
		margin-top: 7px;
	}

	.cart-placeorder .cart-placeorder-btn {
		margin: 15px !important;
	}

	.shipping-title,
	.total-text,
	.total-price {
		font-weight: 600;
		color: #424553;
	}

	.shipping_location .icon,
	.edit-details-wrap {
		color: #696b79;
	}

	.shipping_text,
	.subtotal-text,
	.subtotal-price {
		font-weight: 400;
		color: #696b79;
	}

	.cart-shipping {
		margin-bottom: 15px;
	}

	.order-payment,
	.thank-msg,
	.thankyouPage .linkText-black {
		font-family: "Heebo", sans-serif;
	}

	.single-product .other-related-products {
		margin-bottom: 10px;
		background-color: #fff;
	}

	.mainClass {
		box-shadow: 0 0 11px #ccc;
	}

	.related-product.featureProduct_wrapper {
		padding-top: 0;
	}

	.suppliers-single-page .padding_side_15 {
		padding-top: 20px;
	}

	.suppliers-single-page .suppliers-list {
		border-radius: 0;
		margin-bottom: 10px;
	}

	.find_suppliers {
		.wrapper {
			background-color: transparent;
		}

		.categorywrap {
			background-color: #fff;
			padding: 15px;
		}
	}

	.col-lg-9.col-sm-8 {
		background-color: #fff;
	}

	.suppliers-single-page {
		.nav-bar-single-page {
			padding-bottom: 0;
			border-bottom: 1px solid #dee2e6;
			display: flex;
			flex-wrap: nowrap;
			overflow: auto;

			.link {
				white-space: nowrap;
				padding-left: 12px;
				padding-right: 3px;
			}
		}
	}

	.pagination_wrapper {
		padding-top: 0;
		padding-bottom: 10px;
	}

	.detailsSec {
		padding-bottom: 10px;
	}

	.innerContent {
		padding-top: 0;
		padding-bottom: 10px;

		.wrapper {
			padding-top: 15px;
			padding-bottom: 10px;
		}
	}

	.pricing-page {
		padding-top: 10px;

		h1 {
			font-size: 1.5rem;
			margin-bottom: 14px;
			opacity: 0.8;
		}
	}

	.socialShareWrap {
		background-color: #fff;
	}

	.popular-searches {
		padding-bottom: 10px;
	}

	.breadcrumb-item + .breadcrumb-item {
		max-width: 130px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		vertical-align: middle;
	}

	.suppliers-contents {
		padding-bottom: 15px;

		.btnWrapper {
			margin-top: 0;
		}

		.categorywrap {
			.miniTitle-div {
				margin-bottom: 0;
				border-bottom: none;

				h3 {
					margin-bottom: 0;
				}
			}

			.sidebar-catlist {
				margin-top: 10px;
			}
		}
	}

	.featured_category-mobile .category_wrapper .categoryList {
		background-color: #f5f5f5;
		margin-bottom: 0;
	}

	.suppliers-top {
		.verified.small-verified {
			top: 0;
		}

		&.suppliers-logo {
			padding-top: 25px;
		}
	}

	.InfiniteCarouselFrame {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.InfiniteCarouselScrollTrack {
		margin: 0;
	}

	.filter {
		.fa-filter {
			font-size: 20px;
			opacity: 0.5;

			&.open {
				opacity: 1;
			}
		}

		&.show-in-mobile {
			position: absolute;
			right: 10px;
			left: 0;
			width: 100%;
			text-align: right;
			padding-right: 10px;
		}
	}
}

@media all and (max-width: 575px) {
	.currency {
		display: inline;
	}

	.userList,
	.cartPage {
		.cartItems,
		.cartPrice,
		.cartQty,
		.cartAmnt {
			border-bottom: 0;
			padding-top: 5px;
			padding-bottom: 5px;
			text-align: left;
		}
	}

	.cart-items {
		&.cart-header {
			display: none;
		}

		.cartItems {
			width: 100%;
			flex: 100%;
			max-width: 100%;
			justify-content: flex-start;
		}

		.cartPrice {
			justify-content: flex-start;
		}

		.cartPrice,
		.cartQty {
			width: 50%;
			flex: 50%;
			max-width: 50%;
		}

		.cartAmnt {
			padding-right: 15px;
			font-size: 20px;
			justify-content: flex-end;
			font-weight: 600;
			width: 100%;
			flex: 100%;
			max-width: 100%;
			background-color: $grey;
			margin-top: 10px;
			text-align: right !important;
		}
	}

	.cartPage {
		.col-12 {
			width: 100%;
			max-width: 100%;
			flex: 0 0 100%;
			margin-bottom: 0;
		}

		.userlistWrapper,
		.row .col-12 {
			max-height: initial;
		}
	}

	.cartproduct-image {
		max-width: 60px;
		margin-bottom: 10px;
		float: left;
		margin-right: 15px;

		.imgWrapper {
			padding: 5px;
		}
	}

	.thank-msg {
		&.whiteBox {
			flex-direction: column;
		}
	}
}

//temporary changes
.call-suppliers-btn {
	display: none;
}

.product-meta-wrapper .sold-by-wrapper .call-suppliers-btn {
	padding: 0;
	margin: 0;
}

.suppliers-list {
	padding-bottom: 5px;
}
//temporary changes

.product-name-taglist {
	line-height: 0;
	padding-left: 10px;
	padding-right: 10px;

	+ .socialLink {
		margin-top: 10px;
	}
}

.product-name-tag {
	display: inline-block;

	.tag-name {
		font-size: 11px;
		line-height: 1;
		display: inline-block;
		padding: 3px 5px;
		background-color: #ffedc6;
		margin-right: 5px;
		color: #035586;
		font-weight: 600;
		text-transform: uppercase;
		margin-top: 3px;
		margin-bottom: 3px;
	}
}

.product-name-tag.more-tags {
	display: none;
}

.show-all-tag {
	.product-name-tag.more-tags {
		display: inline-block;
	}

	.tag-arrow {
		transform: rotate(180deg);
	}
}

.tag-arrow {
	padding: 3px;
	background-color: #cae8f9;
	margin-top: 3px;
	font-size: 10px;
	cursor: pointer;
	display: inline-block;

	i {
		color: #035586;
	}
}

.termsPage {
	.innerContent {
		h3 {
			* {
				font-family: $font-family-sans-serif !important;
			}
		}
	}
}
