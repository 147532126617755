// @import '~bootstrap/scss/_mixins.scss';
// @import 'variables';

/*footer*/
.footer {
	&-wrapper {
		footer {
			background-color: #FAFBFC;
			//background-image: url(../images/footer-city.png);
			background-repeat: no-repeat;
			background-position: center 0;
			background-size: 1000px auto;
			position: relative;

		}
	}

	&_top {
		.wrapper {
			display: flex;
			justify-content: space-between;
		}
	}

	&_bottom {
		padding: 40px 20px;

		.wrapper {
			max-width: 1200px;

			.d-flex {
				display: flex;
				justify-content: space-between;

				@media all and ( max-width: 767px ) {
					flex-direction: column;
				}
				
			}			
		}

		&-left {
			width: 33%;

			.fb_r1,
			.fb_r2 {
				justify-content: flex-start;
			}
		}

		&-left,
		&-right {
			display: flex;
			// justify-content: space-between;
			flex-direction: column;
		}

		&-right {
			width: 33%;
		}

		&-center {
			// padding-left: 30px;
			// padding-right: 30px;
		}
	}

	&_address {
		font-size: 14px;
		font-weight: 300;
		color: $white;
		color: #696b79;
	}

	&_phone-div {
		margin-bottom: 0;
	}

	&_social_icons {
		display: flex;
	}

	&_phone-div,
	&_email {
	    display: flex;
	    flex-direction: row;
	    align-items: center;
	    font-size: 13px;
	    // align-items: baseline;

	    > a {
	    	display: flex;
	    	flex-direction: row;
	    	align-items: center;
	    	color: $white;
	    	color: #696b79;

	    	&:hover {
	    		svg {
	    			.a {
	    				opacity: 0.8;
	    			}
	    		}
	    	}
	    }

	    svg {
	    	margin-right: 10px;
	    	width: 20px;
	    	height: 20px;
	    	// min-width: 26px;

			.a {
				fill: #696E79 !important;
				stroke: transparent !important;
			}
		}
		
		&-call {
			svg {
				width: 17px;
				height: 17px;
			}
		}
	}

	&_mail {
		line-height: 1.15;
		word-break: break-all;
	}

	&_phones p,
	&_phones a,
	&_email a {
	    // display: block;
	    font-size: 13px;
	    font-weight: 300;
	    color: $white;
	    color: #696b79;
	}

	&_phone-div a,
	&_email a {
		pointer-events: none;
	}

	&-header {
		position: relative;
		padding-left: 15px;
		padding-right: 15px;
		opacity: 0.7;

		@media all and ( max-width: 767px ) {
			background-color: #fff;
			display: flex;
			padding-top: 7px;
		}

		> p {
			margin-bottom: 10px;
		}

		@media all and ( min-width: 768px ) {
			display: none;
		}

		.arrow {
			display: inline-block;
			font-size: 11px;
			padding-left: 5px;
			position: absolute;
			transform: rotate(180deg);
			right: 10px;
			top: 11px;
		}

		&.show {
			+ .footer-remove {
				height: auto;
			}

			.arrow {
				right: 15px;
				top: 10px;
				transform: rotate(0);
			}
		}
	}

	&-remove {
		@media all and (max-width: 767px) {
			height: 0;
			overflow: hidden;
			padding: 0 !important;
		}
	}
}

@media all and ( max-width: 1024px ) {
	.is-touch-device {
		.footer {
			&_phone-div a,
			&_email a {
				pointer-events: all;
			}
		}
	}
}

span.thin_title {
    font-size: 18px;
    color: #474747;
    display: block;
    padding-bottom: 10px;
}

.payment_way {
	margin-right: 5px;
}

.social {
    display: inline-block;
    margin-right: 10px;
}

.quick_links {
    // padding-left: 50px;
    padding-right: 50px;
    border-right: 1px solid rgba( $white, 0.35 );
    border-left: 1px solid rgba( $white, 0.35 );
    // margin-right: 40px;
}

span.footer_link-title {
    display: block;
    font-size: 12px;
    color: $black1;
    display: block;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 16px;
}

a.footer_menu,
.footer_menu {
    display: block;
    font-size: 15px;
    font-weight: 300;
    color: $white;
    color: #696b79;
    padding-bottom: 5px;
	position: relative;
	
	@media all and ( max-width: 767px ) {
		font-size: 12px;
	}

    &.active {
    	color: $white;
    	color: #696b79;
		text-decoration: underline;
    }
}

a.footer_menu:before {
    content: '';
    border-left: 8px solid $white;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    top: 4px;
    left: 0;
    display: none;
}

.fb {
	&_c1,
	&_c2 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
	}

	&_r1,
	&_r2 {
		display: flex;
	}

	&_r1 {
		img {
			border-radius: 4px;
			margin-right: 10px;
			margin-bottom: 10px;
		}
	}

	&_r2 {
		align-items: end;

		img {
			margin-right: 10px;
			width: 13px;
			position: relative;
			top: 5px;
		}

		p {
			color: $white;
			color: #696b79;
			font-weight: 300;
			font-size: 14px;
			margin-bottom: 0;
			padding-top: 6px;

			a {
				font-weight: 700;
				color: $white;
				color: #696b79;
			}
		}
	}
}

.copyright {
	// background-color: rgba(var(--primary-color), 0.5);
	background-color: #FAFBFC;
	padding: 18px 10px;
	text-align: center;
	border-top: 1px solid #f1efef;
}

.copyright p {
	margin-bottom: 0;
	color: #fff;
	color: #696b79;
	font-size: 14px;
	font-weight: 300;

	a {
		color: $black;

		&:hover {
			color: $black;
			text-decoration: none;
		}
	}
}

.copyright p svg {
	position: relative;
	top: -3px;
	left: -2px;
}

.contact_info {
	// padding-top: 30px;

	.row {
		> div:first-child {
			.footer_phone-div {
				svg {
					width: 13px;
					position: relative;
					top: 2px;
				}
			}

			.footer_email {
				svg {
					width: 15px;
				}
			}
		}
	}

	.footer_link-title {
		padding-bottom: 5px;
	}
}

.popular-searches {
	margin-top: 30px;

	.footer_menu {
		display: inline-block;
		margin-right: 10px;
		border-right: 1px solid #ccc;
		padding-right: 10px;
		padding-bottom: 0;
    	line-height: 1;

    	&:last-child {
    		border-right: none;
    		margin-right: 0;
    		padding-right: 0;
    	}
	}

	.wrapwhite {
		background-color: #fafbfc;
	}
}

.footer_link-title {
	position: relative;

	&.title-withline {
		position: relative;

		&:before {
			content: "";
			width: 100%;
			height: 1px;
			background-color: #f1efef;
			position: absolute;
			top: 9px;
			left: 0;
			z-index: 0;
		}
	}
}

.wrapwhite {
	position: relative;
	background-color: #fff;
	padding-left: 0;
	padding-right: 20px;
	z-index: 1;
}

@media all and ( max-width: 767px ) {
	.footer_bottom-center {
		display: none;
	}

	.mainWrapper {
		display: flex;
		min-height: calc( 100vh - 50px );
		flex-direction: column;
	}

	.mainClass {
		flex: 1;
	}
}

@include media-breakpoint-down ( md ) {
	.fb_r2 p {
		font-size: 12px;
	}

	.footer {
		&_bottom {
			.wrapper {
				flex-wrap: wrap;
			}

			&-left {
				order: 3;
				width: 100%;
				flex: 100%;
				max-width: 100%;
				margin-top: 30px;

				.fb_r1,
				.fb_r2 {
					// justify-content: center;
				}
			}

			&-right {
				order: 2;
				width: 50%;
				padding-left: 30px;
			}

			&-center {
				order: 1;
				padding-left: 0;
				width: 50%;

				.quick_links {
					border-left: 0;
					padding-left: 0;
					display: flex;
					flex-direction: column;
					height: 100%;
				}
			}
		}
	}
}

@include media-breakpoint-down ( sm ) {
	.footer_bottom {
		.wrapper {
			flex-direction: column;
		}

		&-left {
			margin-bottom: 20px;
		}
	}

	.footer {
		&_bottom {
			padding: 0;

			.wrapper {
				flex-wrap: wrap;
			}

			&-left {
				order: 3;
				width: 100%;
				margin-top: 10px;

				.fb_r1,
				.fb_r2 {
					// justify-content: center;
				}
			}

			&-right {
				order: 2;
				width: 100%;
				padding-left: 0;
				margin-top: 30px;
			}

			&-center {
				order: 1;
				padding-left: 0;
				width: 100%;

				.quick_links {
					border-right: 0;
				}
			}
		}
	}

	.copyright p {
		font-size: 12px;
		line-height: 1.15;
		margin-bottom: 5px;
	}
}

@include media-breakpoint-down ( xs ) {
	.footer_bottom-left {
		flex-direction: column;

		.quick_links {
			margin-bottom: 20px;
			border: none;
			padding-right: 0;
			margin-right: 0;
		}
	}

	.footer_top {
		.wrapper {
			flex-direction: column;
		}

		&-left {
			margin-bottom: 20px;
		}		
	}
}

.footer_phone-div,
.footer_email {
	position: relative;

}

.footer_phones,
.footer_mail {
	opacity: 0;
	z-index: -1;
	position: absolute;
	padding: 10px;
	top: -60px;
	left: -70px;
	background-color: #333;
	width: 160px;
	color: #fff;
	border-radius: 5px;
	white-space: nowrap;

	> a {
		color: $white;
	}
}

.footer_phones {
	top: -40px;
}

.footer_phone-div-whatsapp {
	.footer_phones {
		top: -40px;
	}	
}

.footer_phone-div-call,
.footer_phone-div-whatsapp {
	&:hover {
		.footer_phones {
			opacity: 1;
			z-index: 1000;
		}
	}
}

@media ( min-width: 768px ) {
	.menu-right-section .footer_social_icons {
		display: none;
	}
}

@media ( max-width: 767px ) {
	.menu-right-section {
		.footer_phones {
			top: 100%;
		}
	}
}

.footer_email {
	.footer_mail {
		top: -36px;
	}	
}

.footer_email-mail,
.footer_email-fb {
	a:hover {
		.footer_mail {
			opacity: 1;
			z-index: 1000;
		}
	}
}
// for contact phone
.contact_phone{
	display: inline-flex;
	justify-content: center;
	align-items: center;
	// margin-right: 35px;
	font-weight: 600;
	padding: 2px;
    font-size: 16px;
    text-decoration: none;
    padding-bottom: 25px;
    padding-top: 8px;
    border-bottom: 3px solid #fff;
    display: inline-block;
	margin-right: -20px;
	width:200px;
	font-family: "Lato", sans-serif

}
.contact_phone a{
	text-decoration: none;
	color: #3e4152;
	font-size: 16px;
	font-family: "Lato", sans-serif

}
@media ( max-width: 767px ) {
	.contact_phone {		
		display:none
		
	}
}
